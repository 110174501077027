import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  Form,
  FormSelect,
  FormTextInput,
  font,
  Heading,
  Paragraph,
  Select,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import Paginate from "../components/Paginate";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const AccountsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any accounts.</Text>
    </Box>
  </CardContent>
);

const AccountsTable = ({ accounts }: { accounts: any }) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Network</TableHeaderCell>
        <TableHeaderCell align="left">Address</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {accounts.items.map((account: any) => (
        <TableRow key={account.id}>
          <TableLinkCell to={`/accounts/${account.id}`}>{account.name}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/accounts/${account.id}`}>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${account.network.chain.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {account.network.name}
            </Box>
          </TableLinkCell>
          <TableLinkCell
            linkStyle={{ overflow: "hidden", textOverflow: "ellipsis" }}
            style={{ maxWidth: "256px" }}
            tabIndex="-1"
            to={`/accounts/${account.id}`}
          >
            {account.address}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={account.created_at} to={`/accounts/${account.id}`}>
            {format.timestamp(account.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const AccountsList = ({ accounts }: { accounts: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [chain, setChain] = React.useState(undefined);

  const queryClient = useQueryClient();
  const account = useMutation<unknown, unknown, any>(
    ({ address, name, networkId }) =>
      api("/v1/accounts", {
        body: { address, name, network: networkId?.value },
        method: "POST",
      }),
    // triangle.accounts.add({ address, name, network: networkId?.value }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("accounts");
        setIsOpen(false);
      },
    }
  );

  // const chains = useQuery("chains", () => api("/v1/chains"));
  const chains = useQuery("chains", () => triangle.chains.list());
  // const networks = useQuery("networks", () => api("/v1/networks"));
  const networks = useQuery("networks", () => triangle.networks.list());

  if (accounts.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (accounts.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {accounts.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form initialValues={{}} onSubmit={(values: any) => account.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Add an account</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <FormTextInput label="Address" name="address" width="100%" />
              <div style={{ marginTop: spacing.large }}>
                <label style={{ display: "block", fontWeight: font.weight.medium }}>Chain</label>
                <Select
                  // @ts-ignore
                  menuPortalTarget={document.body}
                  name="chainId"
                  onChange={(value: any) => setChain(value.value)}
                  options={
                    chains.data &&
                    chains.data.items.map((chain: any) => ({ label: chain.name, value: chain.id }))
                  }
                  styles={{ container: () => ({ marginTop: spacing.medium }) }}
                />
              </div>
              <FormSelect
                // @ts-ignore
                isDisabled={!chain}
                label="Network"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="networkId"
                options={
                  networks.data &&
                  networks.data.items
                    .filter((network: any) => !chain || network.chain.id === chain)
                    .map((network: any) => ({
                      label: network.name,
                      value: network.id,
                    }))
                }
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={account.isLoading} submit>
                    Add
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {accounts.data.items.length ? (
          <AccountsTable accounts={accounts.data} />
        ) : (
          <AccountsEmpty />
        )}
        <Paginate id="id" list={accounts.data} />
      </CardContainer>
    </>
  );
};

const Accounts = () => {
  const [searchParams] = useSearchParams();
  const accounts = useQuery(["accounts", searchParams.toString()], () =>
    triangle.accounts.list({
      ending_before: searchParams.get("ending_before"),
      expand: ["network.chain"],
      // @ts-ignore
      limit: searchParams.get("limit") || undefined,
      starting_after: searchParams.get("starting_after"),
    })
  );
  return (
    <>
      <Card>
        <Heading level={4}>Accounts</Heading>
        <Paragraph>
          An account is a way to reference a wallet that was created outside of Triangle. This gives
          you read-only controls to make it easy for you to request and receive data about any
          address on any blockchain.{" "}
          <Anchor href="https://www.triangleplatform.com/docs/api/accounts" target="_blank">
            Learn more →
          </Anchor>
        </Paragraph>
      </Card>
      <AccountsList accounts={accounts} />
    </>
  );
};

export default Accounts;
