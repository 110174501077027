import React from "react";
import styled from "styled-components";
import { colors, spacing } from "triangle-ui";

export const CardContainer = styled.div`
  border-radius: ${spacing.medium};
  box-shadow: 0 0 0 1px ${colors.black100};
  overflow: hidden;

  & + & {
    margin-top: ${spacing.large};
  }
`;

export const CardContent = styled.div`
  background: ${colors.white};
  padding: ${spacing.large};

  * + & {
    border-top: 1px solid ${colors.gray100};
  }
`;

type Props = {
  children: React.ReactNode;
  containerProps?: unknown;
  contentProps?: unknown;
};

export const Card = ({ children, containerProps, contentProps }: Props) => (
  <CardContainer {...containerProps}>
    <CardContent {...contentProps}>{children}</CardContent>
  </CardContainer>
);
