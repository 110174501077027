const triangleCsrfToken = document.cookie
  .split("; ")
  .find((c) => c.startsWith("triangle_csrf_token"))!
  .split("=")[1];

type Options = {
  body?: object;
  headers?: object;
  method?: string;
};

const api = async (url: string, options: Options = {}) => {
  const res = await fetch(url, {
    ...options,
    body: JSON.stringify(options.body),
    headers: {
      "content-type": "application/json",
      "triangle-csrf-token": triangleCsrfToken,
      ...options.headers,
    },
  });
  const json = await res.json();
  if (!res.ok) {
    throw json;
  }
  return json;
};

export default api;
