import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  colors,
  Form,
  FormSelect,
  FormTextInput,
  Icon,
  Select,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import Link from "../components/Link";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });
const ERC1155 = "erc1155";
const LIFI_ADDRESS = "0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE";
const BRIDGE_SWAP_SUPPORTED_NETWORKS = [
  "arbitrum_mainnet",
  "aurora_mainnet",
  "avalanche_mainnet",
  "bnbsmartchain_mainnet",
  "boba_mainnet",
  "celo_mainnet",
  "cronos_mainnet",
  "ethereum_mainnet",
  "gnosis_mainnet",
  "fantom_mainnet",
  "moonbeam_mainnet",
  "moonriver_mainnet",
  "optimism_mainnet",
  "polygon_mainnet",
];

const WalletOverview = ({ wallet }: { wallet: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);
  const [isDeployOpen, setIsDeployOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const archive = useMutation<unknown, unknown, any>(
    () => api(`/v1/wallets/${wallet.id}/archive`, { body: {}, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["wallets", wallet.id]);
        navigate(`/vaults/${wallet.vault.id}`);
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ name }) =>
      api(`/v1/wallets/${wallet.id}?expand[]=network.chain&expand[]=vault`, {
        body: { name },
        method: "PATCH",
      }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["wallets", wallet.id], data);
        setIsOpen(false);
      },
    }
  );

  const deploy = useMutation<unknown, unknown, any>(
    () =>
      api(`/v1/wallets/${wallet.id}/deploy_account`, {
        method: "POST",
      }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["wallets", wallet.id], data);
        setIsDeployOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ name: wallet.name }}
          onSubmit={(values: any) => update.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit wallet</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <Modal isOpen={isArchiveOpen} onRequestClose={() => setIsArchiveOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Archive wallet</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to archive <b>{wallet.id}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsArchiveOpen(false)}>Cancel</Button>
                <Button color="red" disabled={archive.isLoading} onClick={() => archive.mutate({})}>
                  Archive
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isDeployOpen} onRequestClose={() => setIsDeployOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Deploy wallet</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to deploy <b>{wallet.id}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsDeployOpen(false)}>Cancel</Button>
                <Button color="blue" disabled={deploy.isLoading} onClick={() => deploy.mutate({})}>
                  Deploy
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="wallet" style={{ marginRight: spacing.small }} />
                {wallet.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {wallet.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {wallet.name}
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>Edit</Button>
              {/* @ts-ignore */}
              <Button onClick={() => setIsArchiveOpen(true)} style={{ color: colors.red }}>
                Archive
              </Button>
              {wallet.vault.type == "account" && (
                <Button disabled={wallet.is_deployed} onClick={() => setIsDeployOpen(true)}>
                  Deploy
                </Button>
              )}
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{}}>
            <Box padding={{ right: spacing.xlarge }}>
              <Text color="black400" size="small">
                Chain
              </Text>
              <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
                <img
                  src={`${process.env.REACT_APP_CDN_HOST}/img/${wallet.network.chain.id}.png`}
                  style={{ height: "16px", marginRight: "8px", width: "16px" }}
                />
                {wallet.network.chain.name}
              </Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Network
              </Text>
              <Box margin={{ top: spacing.small }}>{wallet.network.name}</Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Address
              </Text>
              <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
                {wallet.address}
                <Anchor href={wallet.explorer_url} style={{ display: "flex" }} target="_blank">
                  <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
                </Anchor>
              </Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Vault
              </Text>
              <Box margin={{ top: spacing.small }}>
                <Link to={`/vaults/${wallet.vault.id}`}>{wallet.vault.name}</Link>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const WalletBalanceError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WalletBalanceLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WalletBalanceTable = ({ balance, wallet }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Amount</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>
          <Box flex={{ alignItems: "center" }}>
            <img
              src={balance.logo_url}
              style={{ height: "16px", marginRight: "8px", width: "16px" }}
            />
            {balance.name}
          </Box>
        </TableCell>
        <TableCell>
          {balance.balance} {balance.symbol}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const WalletBalance = ({ wallet }: { wallet: any }) => {
  const [isOpenReceive, setIsOpenReceive] = React.useState(false);
  const [isOpenSend, setIsOpenSend] = React.useState(false);
  const [isOpenSwap, setIsOpenSwap] = React.useState(false);
  const [isOpenBridge, setIsOpenBridge] = React.useState(false);

  // const balance = useQuery(["balance", wallet.id], () =>
  //   api(`/v1/wallets/${wallet.id}/balance`)
  // );
  const balance = useQuery(["balance", wallet.id], () => triangle.wallets.balance(wallet.id));

  const queryClient = useQueryClient();
  const transaction = useMutation<unknown, unknown, any>(
    ({ amount, to }) =>
      api(`/v1/wallets/${wallet.id}/send`, {
        body: {
          amount,
          to,
        },
        method: "POST",
      }),
    // triangle.wallets.send(wallet.id, { amount, to }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSend(false);
      },
    }
  );

  const swapToken = useMutation<unknown, unknown, any>(
    ({ from_token, to_token, amount }) =>
      api(`/v1/wallets/${wallet.id}/swap`, {
        body: {
          from_token: from_token,
          to_token: to_token,
          amount: amount,
        },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSwap(false);
      },
    }
  );

  const bridgeToken = useMutation<unknown, unknown, any>(
    ({ to, from_token, to_token, to_network, amount }) =>
      api(`/v1/wallets/${wallet.id}/bridge`, {
        body: {
          to: to,
          from_token: from_token,
          to_token: to_token,
          to_network: to_network?.value,
          amount: amount,
        },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenBridge(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpenSwap} onRequestClose={() => setIsOpenSwap(false)}>
        <Form
          initialValues={{
            from_token: wallet.network.chain.symbol, // the native token
            to_token: "",
            amount: "",
          }}
          onSubmit={(values: any) => {
            swapToken.mutate(values);
          }}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Swap token</Text>
            </CardContent>
            <CardContent>
              <FormTextInput
                autoFocus
                label="Target Token Address or Symbol"
                name="to_token"
                width="100%"
              />
              <FormTextInput label={`Amount`} name="amount" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenSwap(false)}>Cancel</Button>
                  <Button color="blue" submit>
                    Swap
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <Modal isOpen={isOpenReceive} onRequestClose={() => setIsOpenReceive(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Receive</Text>
          </CardContent>
          <CardContent style={{ textAlign: "center" }}>
            <Box margin={{ bottom: spacing.large }}>
              <Text size="large" weight="bold">
                {wallet.network.chain.name} Wallet Address
              </Text>
            </Box>
            <QRCodeSVG fgColor={colors.black} size={256} value={wallet.address} />
            <Box margin={{ top: spacing.large }}>
              <Text>{wallet.address}</Text>
            </Box>
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsOpenReceive(false)}>Close</Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isOpenSend} onRequestClose={() => setIsOpenSend(false)}>
        <Form
          initialValues={{ from: wallet.address }}
          onSubmit={(values: any) => transaction.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Send a transaction</Text>
            </CardContent>
            <CardContent>
              <FormTextInput disabled label="Address From" name="from" width="100%" />
              <FormTextInput autoFocus label="Address To" name="to" width="100%" />
              <FormTextInput
                label={`Amount (${wallet.network.chain.symbol})`}
                name="amount"
                width="100%"
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenSend(false)}>Cancel</Button>
                  <Button color="blue" disabled={transaction.isLoading} submit>
                    Send
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <Modal isOpen={isOpenBridge} onRequestClose={() => setIsOpenBridge(false)}>
        <Form
          initialValues={{
            from_token: wallet.network.chain.symbol, // the native token
            to_token: "",
            to_network: "",
            amount: "",
          }}
          onSubmit={(values: any) => {
            bridgeToken.mutate(values);
          }}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Bridge token</Text>
            </CardContent>
            <CardContent>
              <FormSelect
                label="Target Chain"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="to_network"
                options={BRIDGE_SWAP_SUPPORTED_NETWORKS.map((chain: string) => ({
                  label: chain,
                  value: chain,
                }))}
              />
              <FormTextInput label="Target Token Address or Symbol" name="to_token" width="100%" />
              <FormTextInput label="To Address" name="to" width="100%" />
              <FormTextInput label={`Amount`} name="amount" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenBridge(false)}>Cancel</Button>
                  <Button color="blue" submit>
                    Bridge
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Balance
            </Text>
            <div>
              {!wallet.network.mainnet && (
                <Button
                  href={`https://faucet.triangleplatform.com/${wallet.network.chain.id}/${
                    wallet.network.id.split("_")[1]
                  }?address=${wallet.address}`}
                  target="_blank"
                >
                  Faucet
                </Button>
              )}
              <Button onClick={() => setIsOpenSend(true)}>Send</Button>
              <Button onClick={() => setIsOpenReceive(true)}>Receive</Button>
              {BRIDGE_SWAP_SUPPORTED_NETWORKS.includes(wallet.network.id) && (
                <Button onClick={() => setIsOpenSwap(true)}>Swap</Button>
              )}
              {BRIDGE_SWAP_SUPPORTED_NETWORKS.includes(wallet.network.id) && (
                <Button onClick={() => setIsOpenBridge(true)}>Bridge</Button>
              )}
            </div>
          </Box>
        </CardContent>
        {balance.isLoading ? (
          <WalletBalanceLoading />
        ) : balance.error ? (
          <WalletBalanceError error={balance.error} />
        ) : (
          <WalletBalanceTable balance={balance.data} wallet={wallet} />
        )}
      </CardContainer>
    </>
  );
};

const WalletNftsEmpty = ({ wallet }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any NFTs in <Text weight="bold">{wallet.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const WalletNftsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WalletNftsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WalletNftsTable = ({ nfts, wallet }: any) => {
  const [isOpenSend, setIsOpenSend] = React.useState("");

  const queryClient = useQueryClient();
  const sendNft = useMutation<unknown, unknown, any>(
    ({ contract, to, amount, token_id }) =>
      api(`/v1/wallets/${wallet.id}/send_nft`, {
        body: {
          contract,
          to,
          amount,
          token_id,
        },
        method: "POST",
      }),
    // triangle.wallets.sendNft(wallet.id, { contract, to, token_id }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["nfts", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSend("");
      },
    }
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell align="left">Name</TableHeaderCell>
          <TableHeaderCell align="left">Token ID</TableHeaderCell>
          <TableHeaderCell align="left">Amount</TableHeaderCell>
          <TableHeaderCell align="left">Contract</TableHeaderCell>
          <TableHeaderCell align="right"></TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {nfts.items.map((nft: any) => (
          <React.Fragment key={`${nft.contract}-${nft.token_id}`}>
            <Modal
              isOpen={isOpenSend === `${nft.contract}-${nft.token_id}`}
              onRequestClose={() => setIsOpenSend("")}
            >
              <Form
                initialValues={{ contract: nft.contract, token_id: nft.token_id, amount: "1" }}
                onSubmit={(values: any) => sendNft.mutate(values)}
              >
                <CardContainer>
                  <CardContent>
                    <Text weight="bold">Send NFT</Text>
                  </CardContent>
                  <CardContent>
                    <FormTextInput disabled label="Contract" name="contract" width="100%" />
                    <FormTextInput autoFocus label="Address To" name="to" width="100%" />
                    {nft.type === ERC1155 && (
                      <FormTextInput label="Amount" name="amount" width="100%" />
                    )}
                    <FormTextInput disabled label="Token ID" name="token_id" width="100%" />
                  </CardContent>
                  <CardContent>
                    <Box flex={{ justifyContent: "space-between" }}>
                      <div />
                      <Box>
                        <Button onClick={() => setIsOpenSend("")}>Cancel</Button>
                        <Button color="blue" disabled={sendNft.isLoading} submit>
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </CardContainer>
              </Form>
            </Modal>
            <TableRow key={nft.contract}>
              <TableCell>
                <Box flex={{ alignItems: "center" }}>
                  <img
                    src={nft.logo_url}
                    style={{ height: "16px", marginRight: "8px", width: "16px" }}
                  />
                  {nft.name}
                </Box>
              </TableCell>
              <TableCell
                style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {nft.token_id}
              </TableCell>
              <TableCell
                style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {nft.type === ERC1155 ? nft.balance : <span>&mdash;</span>}
              </TableCell>
              <TableCell
                style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {nft.contract}
              </TableCell>
              <TableCell align="right">
                <Button onClick={() => setIsOpenSend(`${nft.contract}-${nft.token_id}`)}>
                  Send
                </Button>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const WalletNfts = ({ wallet }: any) => {
  // const nfts = useQuery(["nfts", wallet.id], () => api(`/v1/wallets/${wallet.id}/nfts`));
  const nfts = useQuery(["nfts", wallet.id], () => triangle.wallets.nfts(wallet.id));
  return (
    <>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              NFTs
            </Text>
          </Box>
        </CardContent>
        {nfts.isLoading ? (
          <WalletNftsLoading />
        ) : nfts.error ? (
          <WalletNftsError error={nfts.error} />
        ) : nfts.data.items.length ? (
          <WalletNftsTable nfts={nfts.data} wallet={wallet} />
        ) : (
          <WalletNftsEmpty wallet={wallet} />
        )}
        <CardContent>...</CardContent>
      </CardContainer>
    </>
  );
};

const WalletTokensEmpty = ({ wallet }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any tokens in <Text weight="bold">{wallet.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const WalletTokensError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WalletTokensLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WalletTokensTable = ({ tokens, wallet }: any) => {
  const [isOpenSend, setIsOpenSend] = React.useState("");
  const [isOpenSwap, setIsOpenSwap] = React.useState("");
  const [isOpenBridge, setIsOpenBridge] = React.useState("");
  const [isLoadingApproval, setIsLoadingApproval] = React.useState(true);
  const [isTokenApproved, setIsTokenApproved] = React.useState(false);

  const queryClient = useQueryClient();
  const sendToken = useMutation<unknown, unknown, any>(
    ({ amount, contract, to }) =>
      api(`/v1/wallets/${wallet.id}/send_token`, {
        body: {
          amount,
          contract,
          to,
        },
        method: "POST",
      }),
    // triangle.wallets.sendToken(wallet.id, { amount, contract, to }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["tokens", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSend("");
      },
    }
  );
  const approveToken = useMutation<unknown, unknown, any>(
    ({ contract }) =>
      api(`/v1/wallets/${wallet.id}/approve_token_all`, {
        body: {
          contract_address: contract,
          to: LIFI_ADDRESS,
        },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSwap("");
      },
    }
  );
  const swapToken = useMutation<unknown, unknown, any>(
    ({ contract, to_token, amount }) =>
      api(`/v1/wallets/${wallet.id}/swap`, {
        body: {
          from_token: contract,
          to_token: to_token,
          amount: amount,
        },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenSwap("");
      },
    }
  );

  const bridgeToken = useMutation<unknown, unknown, any>(
    ({ to, contract, to_token, to_network, amount }) =>
      api(`/v1/wallets/${wallet.id}/bridge`, {
        body: {
          to: to,
          from_token: contract,
          to_token: to_token,
          to_network: to_network?.value,
          amount: amount,
        },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["balance", wallet.id]);
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenBridge("");
      },
    }
  );

  const swapBridgeButtonClicked = async (
    token: any,
    setOpenFunc: (value: React.SetStateAction<string>) => void
  ) => {
    setIsLoadingApproval(true);
    setOpenFunc(token.contract);
    const resp = await api(`/v1/wallets/${wallet.id}/is_token_approved`, {
      body: {
        contract_address: token.contract,
        to: LIFI_ADDRESS,
      },
      method: "POST",
    });

    setIsTokenApproved(resp);
    setIsLoadingApproval(false);
  };

  const swapCard = (token: any) => {
    return (
      <CardContainer>
        <CardContent>
          <Text weight="bold">Swap token</Text>
        </CardContent>
        {isTokenApproved ? (
          <CardContent>
            <FormTextInput disabled label="Contract" name="contract" width="100%" />
            <FormTextInput
              autoFocus
              label="Target Token Address or Symbol"
              name="to_token"
              width="100%"
            />
            <FormTextInput label={`Amount (${token.symbol})`} name="amount" width="100%" />
          </CardContent>
        ) : (
          <CardContent>
            <Text weight="bold">Please approve token first</Text>
          </CardContent>
        )}
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <div />
            <Box>
              <Button onClick={() => setIsOpenSwap("")}>Cancel</Button>
              {isTokenApproved ? (
                <Button color="blue" disabled={swapToken.isLoading} submit>
                  Swap
                </Button>
              ) : (
                <Button color="blue" disabled={approveToken.isLoading} submit>
                  Approve
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    );
  };

  const bridgeCard = (token: any) => {
    return (
      <CardContainer>
        <CardContent>
          <Text weight="bold">Bridge token</Text>
        </CardContent>
        {isTokenApproved ? (
          <CardContent>
            <FormTextInput disabled label="Contract" name="contract" width="100%" />
            <FormSelect
              // @ts-ignore
              label="Target Chain"
              // @ts-ignore
              menuPortalTarget={document.body}
              name="to_network"
              options={BRIDGE_SWAP_SUPPORTED_NETWORKS.map((chain: any) => ({
                label: chain,
                value: chain,
              }))}
            />
            <FormTextInput label="Target Token Address or Symbol" name="to_token" width="100%" />
            <FormTextInput label="To Address" name="to" width="100%" />
            <FormTextInput label={`Amount (${token.symbol})`} name="amount" width="100%" />
          </CardContent>
        ) : (
          <CardContent>
            <Text weight="bold">Please approve token first</Text>
          </CardContent>
        )}
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <div />
            <Box>
              <Button onClick={() => setIsOpenBridge("")}>Cancel</Button>
              {isTokenApproved ? (
                <Button color="blue" disabled={bridgeToken.isLoading} submit>
                  Bridge
                </Button>
              ) : (
                <Button color="blue" disabled={approveToken.isLoading} submit>
                  Approve
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    );
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell align="left">Name</TableHeaderCell>
          <TableHeaderCell align="left">Amount</TableHeaderCell>
          <TableHeaderCell align="left">Contract</TableHeaderCell>
          <TableHeaderCell align="right"></TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tokens.items.map((token: any) => (
          <React.Fragment key={token.contract}>
            <Modal isOpen={isOpenSend === token.contract} onRequestClose={() => setIsOpenSend("")}>
              <Form
                initialValues={{ contract: token.contract }}
                onSubmit={(values: any) => sendToken.mutate(values)}
              >
                <CardContainer>
                  <CardContent>
                    <Text weight="bold">Send token</Text>
                  </CardContent>
                  <CardContent>
                    <FormTextInput disabled label="Contract" name="contract" width="100%" />
                    <FormTextInput autoFocus label="Address To" name="to" width="100%" />
                    <FormTextInput label={`Amount (${token.symbol})`} name="amount" width="100%" />
                  </CardContent>
                  <CardContent>
                    <Box flex={{ justifyContent: "space-between" }}>
                      <div />
                      <Box>
                        <Button onClick={() => setIsOpenSend("")}>Cancel</Button>
                        <Button color="blue" disabled={sendToken.isLoading} submit>
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </CardContainer>
              </Form>
            </Modal>
            <Modal
              isOpen={isOpenSwap === token.contract}
              onRequestClose={() => {
                setIsOpenSwap("");
                setIsTokenApproved(false);
              }}
            >
              <Form
                initialValues={{ contract: token.contract }}
                onSubmit={(values: any) => {
                  isTokenApproved ? swapToken.mutate(values) : approveToken.mutate(values);
                }}
              >
                {isLoadingApproval ? (
                  <CardContainer>
                    <CardContent>
                      <Text weight="bold">Checking token approval...</Text>
                    </CardContent>
                  </CardContainer>
                ) : (
                  swapCard(token)
                )}
              </Form>
            </Modal>
            <Modal
              isOpen={isOpenBridge === token.contract}
              onRequestClose={() => {
                setIsOpenBridge("");
                setIsTokenApproved(false);
              }}
            >
              <Form
                initialValues={{ contract: token.contract }}
                onSubmit={(values: any) => {
                  isTokenApproved ? bridgeToken.mutate(values) : approveToken.mutate(values);
                }}
              >
                {isLoadingApproval ? (
                  <CardContainer>
                    <CardContent>
                      <Text weight="bold">Checking token approval...</Text>
                    </CardContent>
                  </CardContainer>
                ) : (
                  bridgeCard(token)
                )}
              </Form>
            </Modal>
            <TableRow>
              <TableCell>
                <Box flex={{ alignItems: "center" }}>
                  <img
                    src={token.logo_url}
                    style={{ height: "16px", marginRight: "8px", width: "16px" }}
                  />
                  {token.name}
                </Box>
              </TableCell>
              <TableCell
                style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {token.balance} {token.symbol}
              </TableCell>
              <TableCell
                style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {token.contract}
              </TableCell>
              <TableCell align="right">
                {BRIDGE_SWAP_SUPPORTED_NETWORKS.includes(wallet.network.id) && (
                  <Button onClick={async () => swapBridgeButtonClicked(token, setIsOpenSwap)}>
                    Swap
                  </Button>
                )}
                {BRIDGE_SWAP_SUPPORTED_NETWORKS.includes(wallet.network.id) && (
                  <Button onClick={async () => swapBridgeButtonClicked(token, setIsOpenBridge)}>
                    Bridge
                  </Button>
                )}
                <Button onClick={() => setIsOpenSend(token.contract)}>Send</Button>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const WalletTokens = ({ wallet }: any) => {
  // const tokens = useQuery(["tokens", wallet.id], () =>
  //   api(`/v1/wallets/${wallet.id}/tokens`)
  // );
  const tokens = useQuery(["tokens", wallet.id], () => triangle.wallets.tokens(wallet.id));

  return (
    <>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Tokens
            </Text>
          </Box>
        </CardContent>
        {tokens.isLoading ? (
          <WalletTokensLoading />
        ) : tokens.error ? (
          <WalletTokensError error={tokens.error} />
        ) : tokens.data.items.length ? (
          <WalletTokensTable tokens={tokens.data} wallet={wallet} />
        ) : (
          <WalletTokensEmpty wallet={wallet} />
        )}
        <CardContent>...</CardContent>
      </CardContainer>
    </>
  );
};

const WalletTransactionsEmpty = ({ wallet }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any transactions in <Text weight="bold">{wallet.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const WalletTransactionsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WalletTransactionsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WalletTransactionsTable = ({ transactions, wallet }: any) => {
  const queryClient = useQueryClient();
  const transaction = useMutation<unknown, unknown, any>(
    ({ transaction }) =>
      api(`/v1/wallets/${wallet.id}/send_raw_transaction`, {
        body: { transaction },
        method: "POST",
      }),
    // triangle.wallets.sendRawTransaction(wallet.id, { transaction }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["transactions", wallet.id]);
      },
    }
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell align="left">Hash</TableHeaderCell>
          <TableHeaderCell align="left">Serialized</TableHeaderCell>
          <TableHeaderCell align="left">Raw</TableHeaderCell>
          <TableHeaderCell align="left">Date</TableHeaderCell>
          <TableHeaderCell align="right"></TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.items.map((t: any) => (
          <TableRow key={t.id}>
            <TableCell style={{ maxWidth: "256px" }}>
              {t.state === "signed" ? (
                <span>&mdash;</span>
              ) : (
                <Box flex={{ alignItems: "center" }}>
                  <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{t.hash}</span>
                  {/* @ts-ignore */}
                  <Anchor
                    href={t.explorer_url}
                    style={{ alignItems: "center", display: "flex" }}
                    target="_blank"
                  >
                    <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
                  </Anchor>
                </Box>
              )}
            </TableCell>
            <TableCell style={{ maxWidth: "128px", overflow: "hidden", textOverflow: "ellipsis" }}>
              {t.serialized}
            </TableCell>
            <TableCell style={{ maxWidth: "128px", overflow: "hidden", textOverflow: "ellipsis" }}>
              {t.raw}
            </TableCell>
            {/* @ts-ignore */}
            <TableCell minimized title={t.created_at}>
              {format.timestamp(t.created_at)}
            </TableCell>
            <TableCell align="right">
              {t.state === "signed" ? (
                <Button
                  disabled={transaction.isLoading}
                  onClick={() => transaction.mutate({ transaction: t.id })}
                >
                  Send
                </Button>
              ) : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const WalletTransactions = ({ wallet }: any) => {
  const [isOpenNew, setIsOpenNew] = React.useState(false);

  const transactions = useQuery(["transactions", wallet.id], () =>
    api(`/v1/transactions?wallet=${wallet.id}`)
  );

  const queryClient = useQueryClient();
  const transaction = useMutation<unknown, unknown, any>(
    ({ send, serialized }) =>
      api(`/v1/wallets/${wallet.id}/${send ? "send_transaction" : "sign_transaction"}`, {
        body: { serialized },
        method: "POST",
      }),
    // triangle.wallets.sendTransaction(wallet.id, { serialized }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["transactions", wallet.id]);
        setIsOpenNew(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpenNew} onRequestClose={() => setIsOpenNew(false)}>
        <Form
          initialValues={{ address: wallet.address }}
          onSubmit={(values: any) => transaction.mutate({ ...values, send: true })}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Send a transaction</Text>
            </CardContent>
            <CardContent>
              <FormTextInput disabled label="Address" name="address" width="100%" />
              <FormTextInput autoFocus label="Serialized" name="serialized" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenNew(false)}>Cancel</Button>
                  <Button
                    onClick={() =>
                      transaction.mutate({
                        send: false,
                        serialized: (
                          document.getElementsByName("serialized")[0] as HTMLInputElement
                        ).value,
                      })
                    }
                    disabled={transaction.isLoading}
                  >
                    Sign
                  </Button>
                  <Button color="blue" disabled={transaction.isLoading} submit>
                    Send
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Transactions
            </Text>
            <div>
              <Button onClick={() => setIsOpenNew(true)}>New</Button>
            </div>
          </Box>
        </CardContent>
        {transactions.isLoading ? (
          <WalletTransactionsLoading />
        ) : transactions.error ? (
          <WalletTransactionsError error={transactions.error} />
        ) : transactions.data.items.length ? (
          <WalletTransactionsTable transactions={transactions.data} wallet={wallet} />
        ) : (
          <WalletTransactionsEmpty wallet={wallet} />
        )}
        <CardContent>...</CardContent>
      </CardContainer>
    </>
  );
};

const WalletMessagesEmpty = ({ wallet }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You haven't signed any messages with <Text weight="bold">{wallet.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const WalletMessagesError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WalletMessagesLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WalletMessagesTable = ({ messages, wallet }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Message</TableHeaderCell>
        <TableHeaderCell align="left">Signature</TableHeaderCell>
        <TableHeaderCell align="left">Date</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {messages.items.map((message: any) => (
        <TableRow key={message.id}>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {message.message || message.encoded}
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {message.signature}
          </TableCell>
          {/* @ts-ignore */}
          <TableCell minimized title={message.created_at}>
            {format.timestamp(message.created_at)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const WalletMessages = ({ wallet }: any) => {
  const [isOpenNew, setIsOpenNew] = React.useState(false);

  const messages = useQuery(["messages", wallet.id], () => api(`/v1/messages?wallet=${wallet.id}`));

  const queryClient = useQueryClient();
  const message = useMutation<unknown, unknown, any>(
    ({ message }) =>
      api(`/v1/wallets/${wallet.id}/sign_message`, {
        body: { message },
        method: "POST",
      }),
    // triangle.wallets.signMessage(wallet.id, { message }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", wallet.id]);
        setIsOpenNew(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpenNew} onRequestClose={() => setIsOpenNew(false)}>
        <Form
          initialValues={{ address: wallet.address }}
          onSubmit={(values: any) => message.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Sign a message</Text>
            </CardContent>
            <CardContent>
              <FormTextInput disabled label="Address" name="address" width="100%" />
              <FormTextInput autoFocus label="Message" name="message" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenNew(false)}>Cancel</Button>
                  <Button color="blue" disabled={message.isLoading} submit>
                    Sign
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Messages
            </Text>
            <div>
              <Button onClick={() => setIsOpenNew(true)}>New</Button>
            </div>
          </Box>
        </CardContent>
        {messages.isLoading ? (
          <WalletMessagesLoading />
        ) : messages.error ? (
          <WalletMessagesError error={messages.error} />
        ) : messages.data.items.length ? (
          <WalletMessagesTable messages={messages.data} wallet={wallet} />
        ) : (
          <WalletMessagesEmpty wallet={wallet} />
        )}
        <CardContent>...</CardContent>
      </CardContainer>
    </>
  );
};

const Wallet = () => {
  const params = useParams();
  const id = params.id!;

  // const wallet: any = useQuery(["wallets", id], () =>
  //   api(`/v1/wallets/${id}?expand[]=network.chain&expand[]=vault`)
  // );
  const wallet: any = useQuery(["wallets", id], () =>
    triangle.wallets.retrieve(id, { expand: ["network.chain", "vault"] })
  );

  if (wallet.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (wallet.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {wallet.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <WalletOverview wallet={wallet.data} />
      <WalletBalance wallet={wallet.data} />
      <WalletTokens wallet={wallet.data} />
      <WalletNfts wallet={wallet.data} />
      <WalletTransactions wallet={wallet.data} />
      <WalletMessages wallet={wallet.data} />
    </>
  );
};

export default Wallet;
