import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Td } from "triangle-ui";

const LinkCell = styled(Link)`
  color: inherit;
  display: block;
  outline: none;
  padding: 8px;
  text-decoration: none;
  td:first-child > & {
    padding-left: 32px;
  }
  td:last-child > & {
    padding-right: 32px;
  }
`;

type Props = {
  children?: React.ReactNode;
  linkStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  tabIndex?: any;
  title?: string;
  to?: any;
};

const TableLinkCell = ({ children, linkStyle, tabIndex, to, ...props }: Props) => (
  <Td {...props}>
    <LinkCell className="cell" style={linkStyle} tabIndex={tabIndex} to={to}>
      {children}
    </LinkCell>
  </Td>
);

export default TableLinkCell;
