import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Box,
  Form,
  FormTextInput,
  colors,
  font,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const App = () => {
  const app: any = useQuery(["apps", "current"], () => api("/v1/apps/current"));

  return (
    <CardContainer>
      <CardContent>
        <Text size="medium" weight="medium">
          App
        </Text>
      </CardContent>
      {app.isLoading ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Loading...</Box>
        </CardContent>
      ) : app.error ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Error: {app.error.message}</Box>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Text size="small">
              <b>ID:</b> {app.data.id}
            </Text>
          </CardContent>
          <CardContent>
            <Text size="small">
              <b>Name:</b> {app.data.name}
            </Text>
          </CardContent>
        </>
      )}
    </CardContainer>
  );
};

const User = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const user = useQuery(["users", "current"], () => api("/v1/users/current"));
  const setup = useQuery(["mfa_setup"], () => api("/v1/users/current/mfa"), {
    enabled: false,
  });

  const queryClient = useQueryClient();
  const verify: any = useMutation<unknown, unknown, any>(
    ({ code }) => api("/v1/users/current/mfa", { body: { code }, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["users", "current"]);
        setIsOpen(false);
      },
    }
  );

  if (user.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterClose={() => setup.remove()}
        onAfterOpen={() => setup.refetch()}
        onRequestClose={() => setIsOpen(false)}
      >
        <Form initialValues={{ code: "" }} onSubmit={(values: any) => verify.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">2FA Setup</Text>
            </CardContent>
            <CardContent>
              <label style={{ display: "block", fontWeight: font.weight.medium }}>QR Code</label>
              {!setup.isFetched || setup.isLoading ? (
                <Box
                  flex={{ alignItems: "center", justifyContent: "center" }}
                  height="256px"
                  margin={{ top: "8px" }}
                >
                  <Text>Loading...</Text>
                </Box>
              ) : (
                <Box
                  flex={{ alignItems: "center", justifyContent: "center" }}
                  height="256px"
                  margin={{ top: "8px" }}
                >
                  <QRCodeSVG
                    fgColor={colors.black}
                    size={256}
                    value={`otpauth://totp/Triangle:${user.data.email}?secret=${setup.data.secret_code}&issuer=Triangle`}
                  />
                </Box>
              )}
              <FormTextInput
                autoFocus
                label="6-digit Code"
                name="code"
                placeholder="000000"
                width="100%"
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={verify.isLoading} submit>
                    Verify
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Text size="medium" weight="medium">
            User
          </Text>
        </CardContent>
        <CardContent>
          <Text size="small">
            <b>ID:</b> {user.data.id}
          </Text>
        </CardContent>
        <CardContent>
          <Text size="small">
            <b>Name:</b> {user.data.name}
          </Text>
        </CardContent>
        <CardContent>
          <Text size="small">
            <b>Email:</b> {user.data.email}
          </Text>
        </CardContent>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <b>Two-Factor Authentication:</b> {"Disabled"}
            </Text>
            <Button onClick={() => setIsOpen(true)}>Setup</Button>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const Team = () => {
  const team: any = useQuery(["apps", "current", "team"], () => api("/v1/apps/current/team"));

  return (
    <CardContainer>
      <CardContent>
        <Text size="medium" weight="medium">
          Team
        </Text>
      </CardContent>
      {team.isLoading ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Loading...</Box>
        </CardContent>
      ) : team.error ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Error: {team.error.message}</Box>
        </CardContent>
      ) : team.data.items.length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell align="left">Name</TableHeaderCell>
              <TableHeaderCell align="left">Email</TableHeaderCell>
              <TableHeaderCell align="left">Date</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {team.data.items.map((t: any) => (
              <TableRow key={t.id}>
                <TableCell>{t.name}</TableCell>
                <TableCell>{t.email}</TableCell>
                {/* @ts-ignore */}
                <TableCell minimized title={t.created_at}>
                  {format.timestamp(t.created_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>You don't have any team members.</Box>
        </CardContent>
      )}
    </CardContainer>
  );
};

const Flags = () => {
  const flags: any = useQuery(["apps", "current", "flags"], () => api("/v1/apps/current/flags"));

  return (
    <CardContainer>
      <CardContent>
        <Text size="medium" weight="medium">
          Flags
        </Text>
      </CardContent>
      {flags.isLoading ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Loading...</Box>
        </CardContent>
      ) : flags.error ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Error: {flags.error.message}</Box>
        </CardContent>
      ) : Object.entries(flags.data).length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell align="left">Key</TableHeaderCell>
              <TableHeaderCell align="left">Value</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(flags.data).map(([key, value]: any) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value.toString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>You don't have any flags.</Box>
        </CardContent>
      )}
    </CardContainer>
  );
};

const MPC = () => {
  const mpcs: any = useQuery(["mpcs"], () => api("/v1/mpcs"));
  const [isOpenNew, setIsOpenNew] = React.useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = React.useState("");
  const [isOpenDelete, setIsOpenDelete] = React.useState("");

  const queryClient = useQueryClient();
  const mpc = useMutation<unknown, unknown, any>(
    ({ name, url }) =>
      api(`/v1/mpcs`, {
        body: { name, url },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["mpcs"]);
        setIsOpenNew(false);
      },
    }
  );
  const updateMpc = useMutation<unknown, unknown, any>(
    ({ id, name, url }) =>
      api(`/v1/mpcs/${id}`, {
        body: { name, url },
        method: "PATCH",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["mpcs"]);
        setIsOpenUpdate("");
      },
    }
  );
  const deleteMpc = useMutation<unknown, unknown, any>(
    ({ id }) =>
      api(`/v1/mpcs/${id}`, {
        body: {},
        method: "DELETE",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["mpcs"]);
        setIsOpenDelete("");
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpenNew} onRequestClose={() => setIsOpenNew(false)}>
        <Form initialValues={{}} onSubmit={(values: any) => mpc.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Add a MPC node</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <FormTextInput label="URL" name="url" placeholder="https://" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenNew(false)}>Cancel</Button>
                  <Button color="blue" disabled={mpc.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              MPC Nodes
            </Text>
            <Button onClick={() => setIsOpenNew(true)}>New</Button>
          </Box>
        </CardContent>
        {mpcs.isLoading ? (
          <CardContent>
            <Box flex={{ justifyContent: "center" }}>Loading...</Box>
          </CardContent>
        ) : mpcs.error ? (
          <CardContent>
            <Box flex={{ justifyContent: "center" }}>Error: {mpcs.error.message}</Box>
          </CardContent>
        ) : mpcs.data.items.length ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell align="left">Name</TableHeaderCell>
                <TableHeaderCell align="left">URL</TableHeaderCell>
                <TableHeaderCell align="left">Date</TableHeaderCell>
                <TableHeaderCell align="right"></TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {mpcs.data.items.map((m: any) => (
                <React.Fragment key={m.id}>
                  <TableRow key={m.id}>
                    <TableCell>{m.name}</TableCell>
                    <TableCell>{m.url}</TableCell>
                    {/* @ts-ignore */}
                    <TableCell minimized title={m.created_at}>
                      {format.timestamp(m.created_at)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          setIsOpenUpdate(m.id);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        color="red"
                        onClick={() => {
                          setIsOpenDelete(m.id);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                  <Modal isOpen={isOpenUpdate === m.id} onRequestClose={() => setIsOpenUpdate("")}>
                    <Form
                      initialValues={{
                        id: m.id,
                        name: m.name,
                        url: m.url,
                      }}
                      onSubmit={(values: any) => updateMpc.mutate(values)}
                    >
                      <CardContainer>
                        <CardContent>
                          <Text weight="bold">Update MPC Node</Text>
                        </CardContent>
                        <CardContent>
                          <FormTextInput autoFocus label="Name" name="name" width="100%" />
                          <FormTextInput
                            label="URL"
                            name="url"
                            placeholder="https://"
                            width="100%"
                          />
                        </CardContent>
                        <CardContent>
                          <Box flex={{ justifyContent: "space-between" }}>
                            <div />
                            <Box>
                              <Button onClick={() => setIsOpenUpdate("")}>Cancel</Button>
                              <Button color="blue" disabled={updateMpc.isLoading} submit>
                                Update
                              </Button>
                            </Box>
                          </Box>
                        </CardContent>
                      </CardContainer>
                    </Form>
                  </Modal>
                  <Modal isOpen={isOpenDelete === m.id} onRequestClose={() => setIsOpenDelete("")}>
                    <CardContainer>
                      <CardContent>
                        <Text weight="bold">Delete MPC Node</Text>
                      </CardContent>
                      <CardContent>Are you sure you want to delete "{m.name}"?</CardContent>
                      <CardContent>
                        <Box flex={{ justifyContent: "space-between" }}>
                          <div />
                          <Box>
                            <Button onClick={() => setIsOpenDelete("")}>Cancel</Button>
                            <Button
                              color="red"
                              disabled={deleteMpc.isLoading}
                              onClick={() => {
                                deleteMpc.mutate({
                                  id: m.id,
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </CardContainer>
                  </Modal>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        ) : (
          <CardContent>
            <Box flex={{ justifyContent: "center" }}>You don't have MPC nodes set up.</Box>
          </CardContent>
        )}
      </CardContainer>
    </>
  );
};

const Settings = () => {
  return (
    <>
      <App />
      <User />
      <Team />
      <Flags />
      <MPC />
    </>
  );
};

export default Settings;
