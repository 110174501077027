import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Triangle from "triangle";
import {
  Box,
  Form,
  FormSelect,
  FormTextInput,
  font,
  Heading,
  colors,
  Select,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });
const SUPPORTED_CHAINS = ["ethereum", "arbitrum", "bnbsmartchain", "base"];

const NodesEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>You don't have any nodes.</Text>
    </Box>
  </CardContent>
);

const NodesError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const NodesLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const NodesTable = ({ nodes }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Network</TableHeaderCell>
        <TableHeaderCell align="left">Status</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {nodes.data.items.map((node: any) => (
        <TableRow key={node.id}>
          <TableLinkCell
            style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
            to={`/nodes/${node.id}`}
          >
            {node.name}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/nodes/${node.id}`}>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${node.network?.chain?.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {node.network?.name}
            </Box>
          </TableLinkCell>
          <TableLinkCell
            style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
            to={`/nodes/${node.id}`}
          >
            {node.status}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={node.created_at} to={`/nodes/${node.id}`}>
            {format.timestamp(node.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const NodesList = ({ nodes }: { nodes: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [chain, setChain] = React.useState(undefined);

  const queryClient = useQueryClient();
  const node = useMutation<unknown, unknown, any>(
    ({ name, networkId }) =>
      api("/v1/nodes", { body: { name, network: networkId.value }, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("nodes");
        setIsOpen(false);
      },
    }
  );

  const chains = useQuery("chains", () => triangle.chains.list());
  const networks = useQuery("networks", () => triangle.networks.list());

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form initialValues={{}} onSubmit={(values: any) => node.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create a Node</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <div style={{ marginTop: spacing.large }}>
                <label style={{ display: "block", fontWeight: font.weight.medium }}>Chain</label>
                <Select
                  // @ts-ignore
                  menuPortalTarget={document.body}
                  name="chainId"
                  onChange={(value: any) => setChain(value.value)}
                  options={
                    chains.data &&
                    chains.data.items
                      .map((chain: any) => ({ label: chain.name, value: chain.id }))
                      .filter((chain: any) => SUPPORTED_CHAINS.includes(chain.value))
                  }
                  styles={{ container: () => ({ marginTop: spacing.medium }) }}
                />
              </div>
              <FormSelect
                // @ts-ignore
                isDisabled={!chain}
                label="Network"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="networkId"
                options={
                  networks.data &&
                  networks.data.items
                    .filter((network: any) => !chain || network.chain.id === chain)
                    .map((network: any) => ({
                      label: network.name,
                      value: network.id,
                    }))
                }
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={node.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {nodes.isLoading ? (
          <NodesLoading />
        ) : nodes.error ? (
          <NodesError error={nodes.error} />
        ) : nodes.data.items.length ? (
          <NodesTable nodes={nodes} />
        ) : (
          <NodesEmpty />
        )}
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Box>
              {nodes.data &&
                `${nodes.data.items.length} result${nodes.data.items.length === 1 ? "" : "s"}`}
            </Box>
            <Box>
              <Button disabled>Previous</Button>
              <Button disabled>Next</Button>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const Nodes = () => {
  const nodes = useQuery("nodes", () => api("/v1/nodes?expand[]=network"));
  return (
    <>
      <Card>
        <Heading level={4}>Dedicated Nodes</Heading>
      </Card>
      <NodesList nodes={nodes} />
    </>
  );
};

export default Nodes;
