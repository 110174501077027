import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box, CardContent } from "triangle-ui";

import Button from "./Button";

const appendQuery = (url: string, object: any) => {
  const [path, query] = url.split("?");
  const newQuery = new URLSearchParams(query);
  Object.entries(object).map(([k, v]: any) =>
    v === undefined ? newQuery.delete(k) : newQuery.set(k, v)
  );
  return `${path}?${newQuery.toString()}`;
};

type Props = {
  id: string;
  list: any;
};

const Paginate = (props: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const hasNext = searchParams.get("ending_before") || props.list.has_more;
  const hasPrevious =
    searchParams.get("starting_after") ||
    (searchParams.get("ending_before") && props.list.has_more);

  const hrefNext = hasNext
    ? appendQuery(location.pathname, {
        ending_before: undefined,
        limit: searchParams.get("limit") || undefined,
        starting_after: props.list.items.slice(-1)[0][props.id],
      })
    : "";
  const hrefPrevious = hasPrevious
    ? appendQuery(location.pathname, {
        ending_before: props.list.items[0][props.id],
        limit: searchParams.get("limit") || undefined,
        starting_after: undefined,
      })
    : "";

  return (
    <CardContent>
      <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
        <div />
        <Box>
          <Button disabled={!hasPrevious} to={hrefPrevious}>
            Previous
          </Button>
          <Button disabled={!hasNext} to={hrefNext}>
            Next
          </Button>
        </Box>
      </Box>
    </CardContent>
  );
};

export default Paginate;
