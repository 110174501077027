import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  Title,
  Tooltip,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { COLOR } from "../containers/Rpc";

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, BarElement, Title, Tooltip);

const BarChart = ({ data }: any) => {
  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
      },
    },
    responsive: true,
  };

  for (var idx = 0; idx < data.datasets.length; idx += 1) {
    data.datasets[idx].borderColor = COLOR[idx % COLOR.length].borderColor;
    data.datasets[idx].backgroundColor = COLOR[idx % COLOR.length].backgroundColor;
  }

  // @ts-ignore
  return <Bar height={64} options={options} data={data} />;
};

export default BarChart;
