const format = {
  number: (number: string) => parseFloat(number).toLocaleString("en-US"),
  timestamp: (date: string) =>
    new Date(date).toLocaleString("en-US", {
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      month: "short",
      year: new Date(date).getFullYear() === new Date().getFullYear() ? undefined : "numeric",
    }),
};

export default format;
