import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  colors,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import Link from "../components/Link";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const ContractOverview = ({ contract }: any) => {
  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ justifyContent: "space-between" }}>
          <Text size="small">
            <Box flex={{ alignItems: "center" }}>
              <Icon icon="reader" style={{ marginRight: spacing.small }} />
              {contract.object.toUpperCase()}
            </Box>
          </Text>
          <Text family="mono" size="small">
            {contract.id}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="large" weight="bold">
            {contract.name}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{}}>
          <Box padding={{ right: spacing.xlarge }}>
            <Text color="black400" size="small">
              Chain
            </Text>
            <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${contract.network.chain.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {contract.network.chain.name}
            </Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Network
            </Text>
            <Box margin={{ top: spacing.small }}>{contract.network.name}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Address
            </Text>
            <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
              {contract.address}
              <Anchor href={contract.explorer_url} style={{ display: "flex" }} target="_blank">
                <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
              </Anchor>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </CardContainer>
  );
};

const ContractBalanceError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ContractBalanceLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ContractBalanceTable = ({ balance, contract }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Amount</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>
          <Box flex={{ alignItems: "center" }}>
            <img
              src={balance.logo_url}
              style={{ height: "16px", marginRight: "8px", width: "16px" }}
            />
            {balance.name}
          </Box>
        </TableCell>
        <TableCell>
          {balance.balance} {balance.symbol}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const ContractBalance = ({ contract }: any) => {
  const [isOpenReceive, setIsOpenReceive] = React.useState(false);

  // const balance = useQuery(["balance", contract.id], () =>
  //   api(`/v1/contracts/${contract.id}/balance`)
  // );
  const balance = useQuery(["balance", contract.id], () => triangle.contracts.balance(contract.id));

  return (
    <>
      <Modal isOpen={isOpenReceive} onRequestClose={() => setIsOpenReceive(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Receive</Text>
          </CardContent>
          <CardContent style={{ textAlign: "center" }}>
            <Box margin={{ bottom: spacing.large }}>
              <Text size="large" weight="bold">
                {contract.network.chain.name} Wallet Address
              </Text>
            </Box>
            <QRCodeSVG fgColor={colors.black} size={256} value={contract.address} />
            <Box margin={{ top: spacing.large }}>
              <Text>{contract.address}</Text>
            </Box>
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsOpenReceive(false)}>Close</Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Balance
            </Text>
            <div>
              <Button onClick={() => setIsOpenReceive(true)}>Receive</Button>
            </div>
          </Box>
        </CardContent>
        {balance.isLoading ? (
          <ContractBalanceLoading />
        ) : balance.error ? (
          <ContractBalanceError error={balance.error} />
        ) : (
          <ContractBalanceTable contract={contract} balance={balance.data} />
        )}
      </CardContainer>
    </>
  );
};

const ContractNftsEmpty = ({ contract }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any NFTs in <Text weight="bold">{contract.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const ContractNftsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ContractNftsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ContractNftsTable = ({ contract, nfts }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Token ID</TableHeaderCell>
        <TableHeaderCell align="left">Contract</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {nfts.items.map((nft: any) => (
        <TableRow key={`${nft.contract}-${nft.token_id}`}>
          <TableCell>
            <Box flex={{ alignItems: "center" }}>
              {nft.logo_url?.endsWith(".mp4") ? (
                <video style={{ height: "16px", marginRight: "8px", width: "16px" }}>
                  <source src={nft.logo_url} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={nft.logo_url}
                  style={{ height: "16px", marginRight: "8px", width: "16px" }}
                />
              )}
              {nft.name}
            </Box>
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {nft.token_id}
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {nft.contract}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ContractNfts = ({ contract }: any) => {
  // const nfts = useQuery(["nfts", contract.id], () => api(`/v1/contracts/${contract.id}/nfts`));
  const nfts = useQuery(["nfts", contract.id], () => triangle.contracts.nfts(contract.id));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            NFTs
          </Text>
        </Box>
      </CardContent>
      {nfts.isLoading ? (
        <ContractNftsLoading />
      ) : nfts.error ? (
        <ContractNftsError error={nfts.error} />
      ) : nfts.data.items.length ? (
        <ContractNftsTable contract={contract} nfts={nfts.data} />
      ) : (
        <ContractNftsEmpty contract={contract} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const ContractTokensEmpty = ({ contract }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any tokens in <Text weight="bold">{contract.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const ContractTokensError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ContractTokensLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ContractTokensTable = ({ contract, tokens }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Amount</TableHeaderCell>
        <TableHeaderCell align="left">Contract</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {tokens.items.map((token: any) => (
        <TableRow key={token.contract}>
          <TableCell>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={token.logo_url}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {token.name}
            </Box>
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {token.balance} {token.symbol}
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {token.contract}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ContractTokens = ({ contract }: any) => {
  // const tokens = useQuery(["tokens", contract.id], () =>
  //   api(`/v1/contracts/${contract.id}/tokens`)
  // );
  const tokens = useQuery(["tokens", contract.id], () => triangle.contracts.tokens(contract.id));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Tokens
          </Text>
        </Box>
      </CardContent>
      {tokens.isLoading ? (
        <ContractTokensLoading />
      ) : tokens.error ? (
        <ContractTokensError error={tokens.error} />
      ) : tokens.data.items.length ? (
        <ContractTokensTable contract={contract} tokens={tokens.data} />
      ) : (
        <ContractTokensEmpty contract={contract} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const ContractTransactionsEmpty = ({ contract }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any transactions in <Text weight="bold">{contract.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const ContractTransactionsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ContractTransactionsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ContractTransactionsTable = ({ contract, transactions }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Hash</TableHeaderCell>
        <TableHeaderCell align="left">Block</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {transactions.items.map((t: any) => (
        <TableRow key={t.id}>
          <TableCell style={{ maxWidth: "128px" }}>
            <Box flex={{ alignItems: "center" }}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{t.hash}</span>
              {/* @ts-ignore */}
              {/* <Anchor
                href={t.explorer_url}
                style={{ alignItems: "center", display: "flex" }}
                target="_blank"
              >
                <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
              </Anchor> */}
            </Box>
          </TableCell>
          <TableCell>{t.block}</TableCell>
          <TableCell title={t.created_at}>{format.timestamp(t.created_at)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ContractTransactions = ({ contract }: any) => {
  // const transactions = useQuery(["transactions", contract.id], () =>
  //   api(`/v1/contracts/${contract.id}/transactions`)
  // );
  const transactions = useQuery(["transactions", contract.id], () =>
    triangle.contracts.transactions(contract.id)
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Transactions
          </Text>
        </Box>
      </CardContent>
      {transactions.isLoading ? (
        <ContractTransactionsLoading />
      ) : transactions.error ? (
        <ContractTransactionsError error={transactions.error} />
      ) : transactions.data.items.length ? (
        <ContractTransactionsTable contract={contract} transactions={transactions.data} />
      ) : (
        <ContractTransactionsEmpty contract={contract} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Contract = () => {
  const params = useParams();
  const id = params.id!;

  // const contract: any = useQuery(["contract", id], () =>
  //   api(`/v1/contracts/${id}?expand[]=network.chain`)
  // );
  const contract: any = useQuery(["contract", id], () =>
    triangle.contracts.retrieve(id, { expand: ["network.chain"] })
  );

  if (contract.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (contract.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {contract.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <ContractOverview contract={contract.data} />
      <ContractBalance contract={contract.data} />
      <ContractTokens contract={contract.data} />
      <ContractNfts contract={contract.data} />
      <ContractTransactions contract={contract.data} />
    </>
  );
};

export default Contract;
