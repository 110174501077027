import React from "react";
import ReactModal from "react-modal";
import { colors } from "triangle-ui";

type Props = {
  children?: React.ReactNode;
  id?: string;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onRequestClose?: () => void;
};

export const Modal = (props: Props) => (
  <ReactModal
    id={props.id}
    isOpen={props.isOpen}
    onAfterClose={props.onAfterClose}
    onAfterOpen={props.onAfterOpen}
    onRequestClose={props.onRequestClose}
    style={{
      content: {
        background: undefined,
        border: undefined,
        borderRadius: undefined,
        inset: undefined,
        margin: "auto",
        padding: undefined,
        position: undefined,
        width: "512px",
      },
      overlay: {
        backgroundColor: "rgba(192, 192, 192, 0.75)",
        display: "flex",
        zIndex: 1,
      },
    }}
  >
    {props.children}
  </ReactModal>
);
