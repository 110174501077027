import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  Form,
  FormSelect,
  FormTextInput,
  font,
  Heading,
  Paragraph,
  Select,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import Paginate from "../components/Paginate";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const WalletsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any wallets.</Text>
    </Box>
  </CardContent>
);

const WalletsTable = ({ wallets }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Wallet</TableHeaderCell>
        <TableHeaderCell align="left">Network</TableHeaderCell>
        <TableHeaderCell align="left">Address</TableHeaderCell>
        <TableHeaderCell align="left">Vault</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {wallets.items.map((wallet: any) => (
        <TableRow key={wallet.id}>
          <TableLinkCell to={`/wallets/${wallet.id}`}>{wallet.name}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/wallets/${wallet.id}`}>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${wallet.network.chain.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {wallet.network.name}
            </Box>
          </TableLinkCell>
          <TableLinkCell
            linkStyle={{ overflow: "hidden", textOverflow: "ellipsis" }}
            style={{ maxWidth: "256px" }}
            tabIndex="-1"
            to={`/wallets/${wallet.id}`}
          >
            {wallet.address}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/wallets/${wallet.id}`}>
            {wallet.vault.name}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={wallet.created_at} to={`/wallets/${wallet.id}`}>
            {format.timestamp(wallet.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const WalletsList = ({ wallets }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [chain, setChain] = React.useState(undefined);

  const queryClient = useQueryClient();
  const wallet = useMutation<unknown, unknown, any>(
    ({ name, networkId, vaultId }) =>
      api("/v1/wallets", {
        body: { name, network: networkId?.value, vault: vaultId?.value },
        method: "POST",
      }),
    // triangle.wallets.create({ name, network: networkId?.value, vault: vaultId?.value }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("wallets");
        setIsOpen(false);
      },
    }
  );

  // const chains = useQuery("chains", () => api("/v1/chains"));
  const chains = useQuery("chains", () => triangle.chains.list());
  // const networks = useQuery("networks", () => api("/v1/networks"));
  const networks = useQuery("networks", () => triangle.networks.list());
  // const vaults = useQuery("vaults", () => api("/v1/vaults"));
  const vaults = useQuery("vaults", () => triangle.vaults.list());

  if (wallets.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (wallets.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {wallets.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form initialValues={{}} onSubmit={(values: any) => wallet.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create a wallet</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <div style={{ marginTop: spacing.large }}>
                <label style={{ display: "block", fontWeight: font.weight.medium }}>Chain</label>
                <Select
                  // @ts-ignore
                  menuPortalTarget={document.body}
                  name="chainId"
                  onChange={(value: any) => setChain(value.value)}
                  options={
                    chains.data &&
                    chains.data.items.map((chain: any) => ({ label: chain.name, value: chain.id }))
                  }
                  styles={{ container: () => ({ marginTop: spacing.medium }) }}
                />
              </div>
              <FormSelect
                // @ts-ignore
                isDisabled={!chain}
                label="Network"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="networkId"
                options={
                  networks.data &&
                  networks.data.items
                    .filter((network: any) => !chain || network.chain.id === chain)
                    .map((network: any) => ({
                      label: network.name,
                      value: network.id,
                    }))
                }
              />
              <FormSelect
                label="Vault"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="vaultId"
                options={
                  vaults.data &&
                  vaults.data.items.map((vault: any) => ({ label: vault.name, value: vault.id }))
                }
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={wallet.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {wallets.data.items.length ? <WalletsTable wallets={wallets.data} /> : <WalletsEmpty />}
        <Paginate id="id" list={wallets.data} />
      </CardContainer>
    </>
  );
};

const Wallets = () => {
  // const wallets = useQuery("wallets", () =>
  //   api("/v1/wallets?expand[]=network.chain&expand[]=vault")
  // );
  // const wallets = useQuery("wallets", () =>
  //   triangle.wallets.list({ expand: ["network.chain", "vault"] })
  // );
  const [searchParams] = useSearchParams();
  const wallets = useQuery(["wallets", searchParams.toString()], () =>
    triangle.wallets.list({
      ending_before: searchParams.get("ending_before"),
      expand: ["network.chain", "vault"],
      // @ts-ignore
      limit: searchParams.get("limit") || undefined,
      starting_after: searchParams.get("starting_after"),
    })
  );
  return (
    <>
      <Card>
        <Heading level={4}>Wallets</Heading>
        <Paragraph>
          A wallet is an just like an externally-owned account (EOA). You can use it just like any
          other wallet to sign and send any transaction to the network.{" "}
          <Anchor href="https://www.triangleplatform.com/docs/api/wallets" target="_blank">
            Learn more →
          </Anchor>
        </Paragraph>
      </Card>
      <WalletsList wallets={wallets} />
    </>
  );
};

export default Wallets;
