import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { COLOR } from "../containers/Rpc";
import { chartwindow } from "../containers/Rpc";
import { colors } from "triangle-ui";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, timewindow }: any) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "center",
      },
      tooltip: {
        intersect: false,
        mode: "index",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit:
            timewindow === chartwindow.HOUR
              ? "minute"
              : timewindow === chartwindow.DAY
              ? "minute"
              : timewindow === chartwindow.WEEK
              ? "day"
              : timewindow === chartwindow.MONTH
              ? "day"
              : timewindow === chartwindow.YEAR
              ? "month"
              : "day",
        },
        ticks: {
          maxTicksLimit:
            timewindow === chartwindow.HOUR
              ? 6
              : timewindow === chartwindow.DAY
              ? 12
              : timewindow === chartwindow.WEEK
              ? 8
              : timewindow === chartwindow.MONTH
              ? 15
              : timewindow === chartwindow.YEAR
              ? 12
              : 10,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  for (var idx = 0; idx < data.length; idx += 1) {
    data[idx].borderColor = COLOR[idx % COLOR.length].borderColor;
    data[idx].backgroundColor = COLOR[idx % COLOR.length].backgroundColor;
  }
  const lineData = { datasets: data };

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: any, args: any, plugins: any) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
      } = chart;

      if (tooltip._active.length > 0) {
        const xCoor = tooltip.dataPoints[0].element.x;

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.gray300;
        ctx.setLineDash([3, 3]);
        ctx.moveTo(xCoor, top);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  // @ts-ignore
  return <Line height={64} options={options} data={lineData} plugins={[hoverLine]} />;
};

export default LineChart;
