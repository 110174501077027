import React from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import api from "../utils/api";
import Link from "../components/Link";

const Verify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const resend: any = useMutation<unknown, unknown, any>(
    ({ email }) => api("/v1/resend", { body: { email }, method: "POST" }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: (data: any) => window.alert(data.message),
    }
  );
  const verify: any = useMutation<unknown, unknown, any>(
    ({ token }) => api("/v1/verify", { body: { token }, method: "POST" }),
    {
      // onError: (err: any) => window.alert(err.message),
      // onSuccess: (data: any) => window.alert(data.message),
    }
  );

  React.useEffect(() => {
    if (token) {
      verify.mutate({ token });
    }
  }, []);

  if (token) {
    return (
      <Box style={{ margin: "auto", width: "512px" }}>
        <CardContainer style={{ marginTop: "128px" }}>
          <CardContent style={{ padding: "64px" }}>
            <div style={{ textAlign: "center" }}>
              <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
            </div>
            <Heading level={2} style={{ textAlign: "center" }}>
              Verify
            </Heading>
            <Box style={{ textAlign: "center" }}>
              {verify.data
                ? verify.data.message
                : verify.error
                ? verify.error.message
                : "Loading..."}
            </Box>
            {verify.data && (
              <Box margin={{ top: spacing.xlarge }} style={{ width: "100%" }}>
                <Button onClick={() => navigate("/login")} style={{ width: "100%" }}>
                  Back to Login
                </Button>
              </Box>
            )}
          </CardContent>
        </CardContainer>
      </Box>
    );
  }

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Verify
          </Heading>
          <Box style={{ textAlign: "center" }}>
            Check <b>{email || "?"}</b> to verify your email and get started.
          </Box>
          <Box margin={{ top: spacing.xlarge }} style={{ width: "100%" }}>
            <Button
              disabled={resend.isLoading}
              onClick={() => resend.mutate({ email })}
              style={{ width: "100%" }}
            >
              Resend Email
            </Button>
          </Box>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Verify;
