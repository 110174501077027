import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  colors,
  font,
  Form,
  FormSelect,
  FormTextInput,
  Icon,
  Select,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import Paginate from "../components/Paginate";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const VaultOverview = ({ vault }: { vault: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const archive = useMutation<unknown, unknown, any>(
    () => api(`/v1/vaults/${vault.id}/archive`, { body: {}, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["vaults", vault.id]);
        navigate("/vaults");
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ name }) => api(`/v1/vaults/${vault.id}`, { body: { name }, method: "PATCH" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["vaults", vault.id], data);
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ name: vault.name }}
          onSubmit={(values: any) => update.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit vault</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <Modal isOpen={isArchiveOpen} onRequestClose={() => setIsArchiveOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Archive vault</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to archive <b>{vault.id}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsArchiveOpen(false)}>Cancel</Button>
                <Button color="red" disabled={archive.isLoading} onClick={() => archive.mutate({})}>
                  Archive
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="lockClosed" style={{ marginRight: spacing.small }} />
                {vault.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {vault.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {vault.name}
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>Edit</Button>
              {/* @ts-ignore */}
              <Button onClick={() => setIsArchiveOpen(true)} style={{ color: colors.red }}>
                Archive
              </Button>
            </div>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const VaultWalletsEmpty = ({ vault }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any wallets in <Text weight="bold">{vault.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const VaultWalletsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const VaultWalletsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const VaultWalletsTable = ({ wallets }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Wallet</TableHeaderCell>
        <TableHeaderCell align="left">Network</TableHeaderCell>
        <TableHeaderCell align="left">Address</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {wallets.items.map((wallet: any) => (
        <TableRow key={wallet.id}>
          <TableLinkCell to={`/wallets/${wallet.id}`}>{wallet.name}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/wallets/${wallet.id}`}>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${wallet.network.chain.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {wallet.network.name}
            </Box>
          </TableLinkCell>
          <TableLinkCell
            linkStyle={{ overflow: "hidden", textOverflow: "ellipsis" }}
            style={{ maxWidth: "256px" }}
            tabIndex="-1"
            to={`/wallets/${wallet.id}`}
          >
            {wallet.address}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={wallet.created_at} to={`/wallets/${wallet.id}`}>
            {format.timestamp(wallet.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const VaultWallets = ({ vault, wallets }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [chain, setChain] = React.useState(undefined);

  const queryClient = useQueryClient();
  const wallet = useMutation<unknown, unknown, any>(
    ({ name, networkId, vaultId }) =>
      api("/v1/wallets", {
        body: {
          name,
          network: networkId?.value,
          vault: vaultId?.value,
        },
        method: "POST",
      }),
    // triangle.wallets.create({ name, network: networkId?.value, vault: vaultId?.value }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["vault_wallets", vault.id]);
        setIsOpen(false);
      },
    }
  );

  // const chains = useQuery("chains", () => api("/v1/chains"));
  const chains = useQuery("chains", () => triangle.chains.list());
  // const networks = useQuery("networks", () => api("/v1/networks"));
  const networks = useQuery("networks", () => triangle.networks.list());
  // const vaults = useQuery("vaults", () => api("/v1/vaults"));
  const vaults = useQuery("vaults", () => triangle.vaults.list());

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ vaultId: { label: vault.name, value: vault.id } }}
          onSubmit={(values: any) => wallet.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create a wallet</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <div style={{ marginTop: spacing.large }}>
                <label style={{ display: "block", fontWeight: font.weight.medium }}>Chain</label>
                <Select
                  // @ts-ignore
                  menuPortalTarget={document.body}
                  name="chainId"
                  onChange={(value: any) => setChain(value.value)}
                  options={
                    chains.data &&
                    chains.data.items.map((chain: any) => ({ label: chain.name, value: chain.id }))
                  }
                  styles={{ container: () => ({ marginTop: spacing.medium }) }}
                />
              </div>
              <FormSelect
                // @ts-ignore
                isDisabled={!chain}
                label="Network"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="networkId"
                options={
                  networks.data &&
                  networks.data.items
                    .filter((network: any) => !chain || network.chain.id === chain)
                    .map((network: any) => ({
                      label: network.name,
                      value: network.id,
                    }))
                }
              />
              <FormSelect
                // @ts-ignore
                isDisabled
                label="Vault"
                // @ts-ignore
                menuPortalTarget={document.body}
                name="vaultId"
                options={
                  vaults.data &&
                  vaults.data.items.map((vault: any) => ({ label: vault.name, value: vault.id }))
                }
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={wallet.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Wallets
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>New</Button>
            </div>
          </Box>
        </CardContent>
        {wallets.isLoading ? (
          <VaultWalletsLoading />
        ) : wallets.error ? (
          <VaultWalletsError error={wallets.error} />
        ) : wallets.data.items.length ? (
          <VaultWalletsTable wallets={wallets.data} />
        ) : (
          <VaultWalletsEmpty vault={vault} />
        )}
        {wallets.data ? <Paginate id="id" list={wallets.data} /> : <CardContent>...</CardContent>}
      </CardContainer>
    </>
  );
};

const Vault = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const id = params.id!;

  // const vault: any = useQuery(["vaults", id], () => api(`/v1/vaults/${id}`));
  const vault: any = useQuery(["vaults", id], () => triangle.vaults.retrieve(id));
  // const wallets = useQuery(["vault_wallets", id], () =>
  //   api(`/v1/wallets?expand[]=network.chain&vault=${id}`)
  // );
  const wallets = useQuery(["vault_wallets", id, searchParams.toString()], () =>
    triangle.wallets.list({
      ending_before: searchParams.get("ending_before"),
      expand: ["network.chain"],
      // @ts-ignore
      limit: searchParams.get("limit") || undefined,
      starting_after: searchParams.get("starting_after"),
      vault: id,
    })
  );

  if (vault.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (vault.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {vault.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <VaultOverview vault={vault.data} />
      <VaultWallets vault={vault.data} wallets={wallets} />
    </>
  );
};

export default Vault;
