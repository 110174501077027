import React from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import api from "../utils/api";
import Link from "../components/Link";

const Mfa = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || undefined;
  const session = searchParams.get("session") || undefined;

  const mfa = useMutation<unknown, unknown, any>(
    ({ code, email, session }) =>
      api("/v1/mfa", { body: { code, email, session }, method: "POST" }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        // const params = new URLSearchParams(this.props.location.search);
        navigate("/");
      },
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Two-Factor Authentication
          </Heading>
          <Form
            initialValues={{
              code: "",
            }}
            onSubmit={(values: any) => mfa.mutate({ ...values, email, session })}
          >
            <FormTextInput
              autoFocus
              label="6-digit
              Code"
              name="code"
              placeholder="000000"
              width="100%"
            />
            <Box padding={{ top: spacing.xlarge }} style={{ width: "100%" }}>
              <Button color="blue" disabled={mfa.isLoading} style={{ width: "100%" }}>
                Continue
              </Button>
            </Box>
          </Form>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Mfa;
