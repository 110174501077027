// @ts-nocheck

import styled from "styled-components";
import { colors, font } from "triangle-ui";

const COLOR = {
  black: colors.black,
  black400: colors.black300,
  blue: colors.blue,
  gray: colors.gray,
  green: colors.green,
  normal: "inherit", //colors.black,
  red: colors.red,
};

const FONT_FAMILY = {
  mono: font.family.mono,
  normal: "inherit",
  sans: font.family.sans,
  serif: font.family.serif,
};

const FONT_SIZE = {
  small: "14px",
  normal: "inherit", //'16px',
  medium: "18px",
  large: "22px",
};

const FONT_WEIGHT = {
  thin: 100,
  light: 300,
  normal: "inherit", //400,
  medium: 500,
  bold: 700,
  heavy: 900,
};

const LINE_HEIGHT = {
  small: "20px",
  normal: "inherit", //'24px',
  medium: "28px",
  large: "32px",
};

type Props = {
  children?: React.ReactNode;
  color?: string;
  size?: string;
  weight?: string;
};

const StyledText = styled.span<Props>`
  color: ${(props) => COLOR[props.color]};
  font-family: ${(props) => FONT_FAMILY[props.family]};
  font-size: ${(props) => FONT_SIZE[props.size]};
  font-weight: ${(props) => FONT_WEIGHT[props.weight]};
  line-height: ${(props) => LINE_HEIGHT[props.size]};
`;

export const Text = (props: Props) => <StyledText {...props} />;

Text.defaultProps = {
  color: "normal",
  family: "normal",
  size: "normal",
  weight: "normal",
};
