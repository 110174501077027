import React from "react";
import styled from "styled-components";
import { colors, font, spacing } from "triangle-ui";

const StyledAnchor = styled.a`
  border-radius: ${spacing.medium};
  color: ${colors.blue};
  font-weight: ${font.weight.bold};
  outline: none;
  text-decoration: none;
  transition: box-shadow 0.25s ease, color 0.25s ease;
  &:focus-visible {
    box-shadow: 0 0 0 4px ${colors.blue}80;
  }
  &:hover {
    color: ${colors.black};
  }
`;

type Props = {
  children: React.ReactNode;
  href: string;
  style?: React.CSSProperties;
  target?: string;
};

// @ts-ignore
const Anchor = React.forwardRef((props: Props, ref) => <StyledAnchor ref={ref} {...props} />);

Anchor.defaultProps = {};

export default Anchor;
