import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  colors,
  font,
  Heading,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const LogsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any logs.</Text>
    </Box>
  </CardContent>
);

const background = (status: number) =>
  status >= 500
    ? colors.red50
    : status >= 400
    ? colors.yellow50
    : status >= 300
    ? colors.blue50
    : colors.green50;
const color = (status: number) =>
  status >= 500
    ? colors.red
    : status >= 400
    ? colors.yellow
    : status >= 300
    ? colors.blue
    : colors.green;

const LogsTable = ({ logs }: { logs: any }) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Method</TableHeaderCell>
        <TableHeaderCell align="left">Endpoint</TableHeaderCell>
        <TableHeaderCell align="left">Status</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {logs.items.map((log: any) => (
        <TableRow key={log.id}>
          <TableLinkCell to={`/logs/${log.id}`}>{log.method}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/logs/${log.id}`}>
            {log.endpoint}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/logs/${log.id}`}>
            <span
              style={{
                background: background(log.status),
                borderRadius: spacing.small,
                color: color(log.status),
                fontWeight: font.weight.medium,
                padding: `${spacing.xsmall} ${spacing.medium}`,
              }}
            >
              {log.status}
            </span>
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={log.created_at} to={`/logs/${log.id}`}>
            {format.timestamp(log.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const LogsList = ({ logs }: { logs: any }) => {
  if (logs.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (logs.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {logs.error.message}</Box>
      </Card>
    );
  }

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Button disabled>Filter</Button>
        </Box>
      </CardContent>
      {logs.data.items.length ? <LogsTable logs={logs.data} /> : <LogsEmpty />}
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            {logs.data.items.length} result{logs.data.items.length === 1 ? "" : "s"}
          </Box>
          <Box>
            <Button disabled>Previous</Button>
            <Button disabled>Next</Button>
          </Box>
        </Box>
      </CardContent>
    </CardContainer>
  );
};

const Logs = () => {
  const logs = useQuery("logs", () => api("/v1/logs"));
  return (
    <>
      <Card>
        <Heading level={4}>Logs</Heading>
      </Card>
      <LogsList logs={logs} />
    </>
  );
};

export default Logs;
