import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Select,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import Paginate from "../components/Paginate";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const TransactionsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any transactions.</Text>
    </Box>
  </CardContent>
);

const TransactionsTable = ({ transactions }: { transactions: any }) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Transaction</TableHeaderCell>
        <TableHeaderCell align="left">Wallet</TableHeaderCell>
        <TableHeaderCell align="left">Network</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {transactions.items.map((t: any) => (
        <TableRow key={t.id}>
          <TableLinkCell
            linkStyle={{ overflow: "hidden", textOverflow: "ellipsis" }}
            style={{ maxWidth: "256px" }}
            to={`/transactions/${t.id}`}
          >
            {t.state === "signed" ? <span>&mdash;</span> : t.hash}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/transactions/${t.id}`}>
            {t.wallet.name}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/transactions/${t.id}`}>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/img/${t.wallet.network.chain.id}.png`}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {t.wallet.network.name}
            </Box>
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={t.created_at} to={`/transactions/${t.id}`}>
            {format.timestamp(t.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const TransactionsList = ({ transactions }: { transactions: any }) => {
  if (transactions.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (transactions.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {transactions.error.message}</Box>
      </Card>
    );
  }

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Button disabled>Filter</Button>
        </Box>
      </CardContent>
      {transactions.data.items.length ? (
        <TransactionsTable transactions={transactions.data} />
      ) : (
        <TransactionsEmpty />
      )}
      <Paginate id="id" list={transactions.data} />
    </CardContainer>
  );
};

const Transactions = () => {
  // const transactions = useQuery("transactions", () =>
  //   api("/v1/transactions?expand[]=wallet.network")
  // );
  const [searchParams] = useSearchParams();
  const transactions = useQuery(["transactions", searchParams.toString()], () =>
    // TODO: Add transactions to triangle library
    api(
      `/v1/transactions?expand[]=wallet.network${
        searchParams.get("ending_before")
          ? `&ending_before=${searchParams.get("ending_before")}`
          : ""
      }${searchParams.get("limit") ? `&limit=${searchParams.get("limit")}` : ""}${
        searchParams.get("starting_after")
          ? `&starting_after=${searchParams.get("starting_after")}`
          : ""
      }`
    )
  );
  return (
    <>
      <Card>
        <Text size="large" weight="bold">
          Transactions
        </Text>
      </Card>
      <TransactionsList transactions={transactions} />
    </>
  );
};

export default Transactions;
