import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  Paragraph,
  spacing,
} from "triangle-ui";

import api from "../utils/api";
import Link from "../components/Link";

const Onboarding = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const app = useMutation<unknown, unknown, any>(
    ({ name }) => api("/v1/apps", { body: { name }, method: "POST" }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: async () => {
        await queryClient.refetchQueries("apps");
        navigate("/");
      },
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <Heading level={4} style={{ textAlign: "center" }}>
            Create an App
          </Heading>
          <Paragraph style={{ marginBottom: "24px", textAlign: "center" }}>
            An app is a workspace for you to group all activity for a single project or environment.
          </Paragraph>
          <Form
            initialValues={{
              name: "",
            }}
            onSubmit={(values: any) => app.mutate(values)}
            // style={{width: "100%"}}
            // validateOnBlur={false}
            // validateOnChange={false}
            // validationSchema={yup.object().shape({
            //   email: yup.string().email().required(),
            //   password: yup.string().required(),
            // })}
          >
            <FormTextInput autoFocus label="Name" name="name" width="100%" />
            <Box padding={{ top: spacing.xlarge }}>
              <Button color="blue" disabled={app.isLoading} style={{ width: "100%" }}>
                Create
              </Button>
            </Box>
          </Form>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Onboarding;
