import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Box,
  Form,
  FormTextInput,
  font,
  Heading,
  colors,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const WebhooksEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>You don't have any webhooks.</Text>
    </Box>
  </CardContent>
);

const WebhooksError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WebhooksLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WebhooksTable = ({ webhooks }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Endpoint</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {webhooks.data.items.map((webhook: any) => (
        <TableRow key={webhook.id}>
          <TableLinkCell
            style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}
            to={`/webhooks/${webhook.id}`}
          >
            {webhook.endpoint}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={webhook.created_at} to={`/webhooks/${webhook.id}`}>
            {format.timestamp(webhook.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const WebhooksList = ({ webhooks }: { webhooks: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const webhook = useMutation<unknown, unknown, any>(
    ({ endpoint }) => api("/v1/webhooks", { body: { endpoint }, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("webhooks");
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form initialValues={{}} onSubmit={(values: any) => webhook.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create a webhook</Text>
            </CardContent>
            <CardContent>
              <FormTextInput
                autoFocus
                label="Endpoint"
                name="endpoint"
                placeholder="https://"
                width="100%"
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={webhook.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {webhooks.isLoading ? (
          <WebhooksLoading />
        ) : webhooks.error ? (
          <WebhooksError error={webhooks.error} />
        ) : webhooks.data.items.length ? (
          <WebhooksTable webhooks={webhooks} />
        ) : (
          <WebhooksEmpty />
        )}
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Box>
              {webhooks.data &&
                `${webhooks.data.items.length} result${
                  webhooks.data.items.length === 1 ? "" : "s"
                }`}
            </Box>
            <Box>
              <Button disabled>Previous</Button>
              <Button disabled>Next</Button>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const Webhooks = () => {
  const webhooks = useQuery("webhooks", () => api("/v1/webhooks"));
  return (
    <>
      <Card>
        <Heading level={4}>Webhooks</Heading>
      </Card>
      <WebhooksList webhooks={webhooks} />
    </>
  );
};

export default Webhooks;
