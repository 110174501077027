import { Link as RRLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { colors, font, spacing } from "triangle-ui";

const StyledLink = styled(RRLink)`
  border-radius: ${spacing.medium};
  color: ${colors.blue};
  font-weight: ${font.weight.bold};
  outline: none;
  text-decoration: none;
  transition: box-shadow 0.25s ease, color 0.25s ease;
  &:focus-visible {
    box-shadow: 0 0 0 4px ${colors.blue}80;
  }
  &:hover {
    color: ${colors.black};
  }
`;

export default StyledLink;
