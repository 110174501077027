import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import api from "../utils/api";

const Forgot = () => {
  const navigate = useNavigate();

  const forgot = useMutation<unknown, unknown, any>(
    ({ email }) => api("/v1/forgot", { body: { email }, method: "POST" }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: (data: any) => window.alert(data.message),
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Forgot your password
          </Heading>
          <Form
            initialValues={{
              email: "",
            }}
            onSubmit={(values: any) => forgot.mutate(values)}
          >
            <FormTextInput autoFocus label="Email" name="email" width="100%" />
            <Box padding={{ top: spacing.xlarge }} style={{ width: "100%" }}>
              <Button color="blue" disabled={forgot.isLoading} style={{ width: "100%" }}>
                Continue
              </Button>
            </Box>
          </Form>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Forgot;
