// @ts-nocheck

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, font, spacing } from "triangle-ui";

// import Icon from '../Icon/Icon';

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.color
      ? { primary: colors[props.color], secondary: colors.white }[props.$type]
      : colors.white};
  border: none;
  border-radius: 8px;
  box-shadow: ${(props) =>
    ({
      primary: `
      0 0 0 0 ${colors.blue}80,
      0 0 0 1px ${props.color ? colors[`${props.color}600`] : colors.gray100},
      0 2px 4px ${colors.gray100}
    `,
      secondary: `
      0 0 0 0 ${colors.blue}80
    `,
    }[props.$type])};
  color: ${(props) =>
    props.color
      ? { primary: colors.white, secondary: colors[props.color] }[props.$type]
      : colors.black};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: ${font.weight.medium};
  line-height: inherit;
  outline: none;
  padding: 4px 12px;
  text-decoration: none;
  transition: box-shadow 0.25s ease, color 0.25s ease;
  width: ${(props) => props.width};
  // icon
  align-items: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &:focus-visible {
    box-shadow: ${(props) =>
      ({
        primary: `
        0 0 0 4px ${colors.blue}80,
        0 0 0 1px ${props.color ? colors[`${props.color}600`] : colors.gray100},
        0 2px 4px ${colors.gray100}
      `,
        secondary: `
        0 0 0 4px ${colors.blue}80
      `,
      }[props.$type])};
  }
  &:hover {
    box-shadow: ${(props) =>
      ({
        primary: `
        0 0 0 0 ${colors.blue}80,
        0 0 0 1px ${props.color ? colors[`${props.color}600`] : colors.gray100},
        0 4px 8px ${colors.gray100}
      `,
        secondary: `
        0 0 0 0 ${colors.blue}80
      `,
      }[props.$type])};
    color: ${(props) =>
      props.color
        ? { primary: colors.white, secondary: colors[`${props.color}300`] }[props.$type]
        : { primary: colors.black, secondary: colors.black300 }[props.$type]};
  }
  &:focus-visible:hover {
    box-shadow: ${(props) =>
      ({
        primary: `
        0 0 0 4px ${colors.blue}80,
        0 0 0 1px ${props.color ? colors[`${props.color}600`] : colors.gray100},
        0 4px 8px ${colors.gray100}
      `,
        secondary: `
        0 0 0 4px ${colors.blue}80
      `,
      }[props.$type])};
  }
  * + & {
    margin-left: 8px;
  }
`;

const Aligner = styled.span`
  user-select: none;
  width: 0;
`;

type Props = {
  children?: React.ReactNode;
  color?: string;
  disabled?: boolen;
  href?: string;
  onClick?: () => void;
  submit?: boolen;
  target?: string;
  to?: string;
};

const Button = ({ children, icon, iconRight, submit, type, ...others }: Props) => (
  <StyledButton
    as={others.href ? "a" : others.to ? Link : "button"}
    type={submit ? "submit" : "button"}
    $type={type}
    {...others}
  >
    {/* {icon && <Icon icon={icon} style={{marginRight: children && spacing.small}} type='outline' />} */}
    {children || <Aligner>&nbsp;</Aligner>}
    {/* {iconRight && <Icon icon={iconRight} style={{marginLeft: children && spacing.small}} />} */}
  </StyledButton>
);

Button.defaultProps = {
  type: "primary",
};

export default Button;
