import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Box,
  Form,
  FormTextInput,
  font,
  Heading,
  colors,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const ApikeysEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>You haven't created any API keys.</Text>
    </Box>
  </CardContent>
);

const ApikeysError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ApikeysLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ApikeysTable = ({ apikeys }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {apikeys.data.items.map((apikey: any) => (
        <TableRow key={apikey.id}>
          <TableLinkCell to={`/apikeys/${apikey.id}`}>{apikey.name}</TableLinkCell>
          <TableLinkCell tabIndex="-1" title={apikey.created_at} to={`/apikeys/${apikey.id}`}>
            {format.timestamp(apikey.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ApikeysList = ({ apikeys }: { apikeys: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const apikey: any = useMutation<unknown, unknown, any>(
    ({ name }) => api("/v1/apikeys", { body: { name }, method: "POST" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("apikeys");
      },
    }
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterClose={() => apikey.reset()}
        onRequestClose={() => setIsOpen(false)}
      >
        <Form initialValues={{ name: "" }} onSubmit={(values: any) => apikey.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create an API key</Text>
            </CardContent>
            <CardContent>
              {apikey.data ? (
                <>
                  <FormTextInput
                    disabled
                    label="Name"
                    name="name"
                    // @ts-ignore
                    value={apikey.data.name}
                    width="100%"
                    style={{ background: colors.black50, color: colors.black300 }}
                  />
                  <FormTextInput
                    disabled
                    label="Secret (only revealed once)"
                    name="secret"
                    // @ts-ignore
                    value={apikey.data.secret}
                    width="100%"
                    style={{ background: colors.black50, color: colors.black300 }}
                  />
                </>
              ) : (
                <FormTextInput autoFocus label="Name" name="name" width="100%" />
              )}
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                {apikey.data ? <div>API key created successfully.</div> : <div />}
                <Box>
                  {apikey.data ? (
                    <Button onClick={() => setIsOpen(false)}>Close</Button>
                  ) : (
                    <>
                      <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                      <Button color="blue" disabled={apikey.isLoading} submit>
                        Create
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {apikeys.isLoading ? (
          <ApikeysLoading />
        ) : apikeys.error ? (
          <ApikeysError error={apikeys.error} />
        ) : apikeys.data.items.length ? (
          <ApikeysTable apikeys={apikeys} />
        ) : (
          <ApikeysEmpty />
        )}
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Box>
              {apikeys.data &&
                `${apikeys.data.items.length} result${apikeys.data.items.length === 1 ? "" : "s"}`}
            </Box>
            <Box>
              <Button disabled>Previous</Button>
              <Button disabled>Next</Button>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const Apikeys = () => {
  const apikeys = useQuery("apikeys", () => api("/v1/apikeys"));
  return (
    <>
      <Card>
        <Heading level={4}>API Keys</Heading>
      </Card>
      <ApikeysList apikeys={apikeys} />
    </>
  );
};

export default Apikeys;
