import React from "react";
import { Route, Routes } from "react-router-dom";

import Account from "./containers/Account";
import Accounts from "./containers/Accounts";
import Apikey from "./containers/Apikey";
import Apikeys from "./containers/Apikeys";
import Contract from "./containers/Contract";
import Contracts from "./containers/Contracts";
import Dashboard from "./containers/Dashboard";
import Event from "./containers/Event";
import Events from "./containers/Events";
import Forgot from "./containers/Forgot";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Log from "./containers/Log";
import Logs from "./containers/Logs";
import Mfa from "./containers/Mfa";
import Node from "./containers/Node";
import Nodes from "./containers/Nodes";
import NotFound from "./containers/NotFound";
import Onboarding from "./containers/Onboarding";
import Reset from "./containers/Reset";
import Rpc from "./containers/Rpc";
import Rpcs from "./containers/Rpcs";
import Settings from "./containers/Settings";
import Signup from "./containers/Signup";
import Transaction from "./containers/Transaction";
import Transactions from "./containers/Transactions";
import Vault from "./containers/Vault";
import Vaults from "./containers/Vaults";
import Verify from "./containers/Verify";
import Wallet from "./containers/Wallet";
import Wallets from "./containers/Wallets";
import Webhook from "./containers/Webhook";
import Webhooks from "./containers/Webhooks";

const App = () => {
  return (
    <Routes>
      <Route element={<Forgot />} path="/forgot" />
      <Route element={<Login />} path="/login" />
      <Route element={<Mfa />} path="/mfa" />
      <Route element={<Reset />} path="/reset" />
      <Route element={<Signup />} path="/signup" />
      <Route element={<Verify />} path="/verify" />

      <Route element={<Dashboard />}>
        <Route element={<Home />} path="/" />

        <Route element={<Account />} path="/accounts/:id" />
        <Route element={<Accounts />} path="/accounts" />
        <Route element={<Apikey />} path="/apikeys/:id" />
        <Route element={<Apikeys />} path="/apikeys" />
        <Route element={<Contract />} path="/contracts/:id" />
        <Route element={<Contracts />} path="/contracts" />
        <Route element={<Event />} path="/events/:id" />
        <Route element={<Events />} path="/events" />
        <Route element={<Log />} path="/logs/:id" />
        <Route element={<Logs />} path="/logs" />
        <Route element={<Node />} path="/nodes/:id" />
        <Route element={<Nodes />} path="/nodes" />
        <Route element={<Rpc />} path="/rpcs/:id" />
        <Route element={<Rpcs />} path="/rpcs" />
        <Route element={<Settings />} path="/settings" />
        <Route element={<Transaction />} path="/transactions/:id" />
        <Route element={<Transactions />} path="/transactions" />
        <Route element={<Vault />} path="/vaults/:id" />
        <Route element={<Vaults />} path="/vaults" />
        <Route element={<Wallet />} path="/wallets/:id" />
        <Route element={<Wallets />} path="/wallets" />
        <Route element={<Webhook />} path="/webhooks/:id" />
        <Route element={<Webhooks />} path="/webhooks" />

        <Route element={<NotFound />} path="*" />
      </Route>
      <Route element={<Onboarding />} path="/onboarding" />
    </Routes>
  );
};

export default App;
