import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Icon, spacing } from "triangle-ui";

import api from "../utils/api";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Text } from "../components/Text";

const TransactionOverview = ({ transaction }: any) => (
  <CardContainer>
    <CardContent>
      <Box flex={{ justifyContent: "space-between" }}>
        <Text size="small">
          <Box flex={{ alignItems: "center" }}>
            <Icon icon="swapHorizontal" style={{ marginRight: spacing.small }} />
            {transaction.data.object.toUpperCase()}
          </Box>
        </Text>
        <Text family="mono" size="small">
          {transaction.data.id}
        </Text>
      </Box>
    </CardContent>
    <CardContent>
      <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
        <Text size="large" weight="bold">
          {transaction.data.name}
        </Text>
        <div></div>
      </Box>
    </CardContent>
  </CardContainer>
);

const Transaction = () => {
  const { id } = useParams();
  const transaction = useQuery(["transaction", id], () => api(`/v1/transactions/${id}`));

  if (transaction.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  }

  return (
    <>
      <TransactionOverview transaction={transaction} />
    </>
  );
};

export default Transaction;
