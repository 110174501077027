import React from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import api from "../utils/api";

const Reset = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const reset = useMutation<unknown, unknown, any>(
    ({ password, token }) => api("/v1/reset", { body: { password, token }, method: "POST" }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: (data: any) => window.alert(data.message),
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Reset your password
          </Heading>
          {reset.data ? (
            <Box margin={{ top: spacing.xlarge }} style={{ width: "100%" }}>
              <Button onClick={() => navigate("/login")} style={{ width: "100%" }}>
                Back to Login
              </Button>
            </Box>
          ) : (
            <Form
              initialValues={{
                password: "",
              }}
              onSubmit={(values: any) => reset.mutate({ ...values, token })}
            >
              <FormTextInput
                autoFocus
                label="Password"
                name="password"
                type="password"
                width="100%"
              />
              <Box padding={{ top: spacing.xlarge }} style={{ width: "100%" }}>
                <Button color="blue" disabled={reset.isLoading} style={{ width: "100%" }}>
                  Continue
                </Button>
              </Box>
            </Form>
          )}
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Reset;
