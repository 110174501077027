import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  colors,
  font,
  Form,
  FormTextInput,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import Button from "../components/Button";
import format from "../utils/format";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const WEBHOOK_SECRET_PLACEHOLDER = "whsec_AbCdEfGhIjKlMnOpQrStUvWxYz0123456789aBcDeFg";

const WebhookOverview = ({ webhook }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const delete_ = useMutation<unknown, unknown, any>(
    () => api(`/v1/webhooks/${webhook.id}`, { body: {}, method: "DELETE" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["webhooks", webhook.id]);
        navigate("/webhooks");
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ endpoint }) => api(`/v1/webhooks/${webhook.id}`, { body: { endpoint }, method: "PATCH" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["webhooks", webhook.id], data);
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isDeleteOpen} onRequestClose={() => setIsDeleteOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Delete webhook</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to delete <b>{webhook.endpoint}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
                <Button color="red" disabled={delete_.isLoading} onClick={() => delete_.mutate({})}>
                  Delete
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ endpoint: webhook.endpoint }}
          onSubmit={(values: any) => update.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit webhook</Text>
            </CardContent>
            <CardContent>
              <FormTextInput
                autoFocus
                label="Endpoint"
                name="endpoint"
                placeholder="https://"
                width="100%"
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="send" style={{ marginRight: spacing.small }} />
                {webhook.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {webhook.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {webhook.endpoint}
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>Edit</Button>
              {/* @ts-ignore */}
              {/* <Button disabled style={{ color: colors.yellow }}>
                Disable
              </Button> */}
              {/* @ts-ignore */}
              <Button onClick={() => setIsDeleteOpen(true)} style={{ color: colors.red }}>
                Delete
              </Button>
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{}}>
            <Box padding={{ right: spacing.xlarge }}>
              <Text color="black400" size="small">
                Status
              </Text>
              <Box margin={{ top: spacing.small }}>Enabled</Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Date
              </Text>
              <Box margin={{ top: spacing.small }}>{format.timestamp(webhook.created_at)}</Box>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const WebhookSecret = ({ webhook }: any) => {
  const secret: any = useMutation<unknown, unknown, any>(
    ({ id }) =>
      api(`/v1/webhooks/${id}/secret`, {
        body: {},
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
    }
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Secret
          </Text>
          {secret.data ? (
            <Button disabled={secret.isLoading} onClick={() => secret.reset()}>
              Hide
            </Button>
          ) : (
            <Button disabled={secret.isLoading} onClick={() => secret.mutate({ id: webhook.id })}>
              View
            </Button>
          )}
        </Box>
      </CardContent>
      <CardContent>
        {secret.data ? (
          <div style={{ fontFamily: font.family.mono }}>{secret.data.secret}</div>
        ) : (
          <div
            style={{
              filter: "blur(4px)",
              fontFamily: font.family.mono,
              userSelect: "none",
            }}
          >
            {WEBHOOK_SECRET_PLACEHOLDER}
          </div>
        )}
      </CardContent>
    </CardContainer>
  );
};

const WebhookAttemptsEmpty = ({ webhook }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any attempts in <Text weight="bold">{webhook.id}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const WebhookAttemptsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const WebhookAttemptsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const WebhookAttemptsTable = ({ webhook, attempts }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Event</TableHeaderCell>
        <TableHeaderCell align="left">Status</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {attempts.items.map((a: any) => (
        <TableRow key={a.id}>
          <TableCell>{a.event.id}</TableCell>
          <TableCell>{a.error || a.response.status}</TableCell>
          <TableCell title={a.created_at}>{format.timestamp(a.created_at)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const WebhookAttempts = ({ webhook }: any) => {
  const attempts = useQuery(["attempts", webhook.id], () =>
    api(`/v1/webhooks/${webhook.id}/attempts`)
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Webhook Attempts
          </Text>
        </Box>
      </CardContent>
      {attempts.isLoading ? (
        <WebhookAttemptsLoading />
      ) : attempts.error ? (
        <WebhookAttemptsError error={attempts.error} />
      ) : attempts.data.items.length ? (
        <WebhookAttemptsTable webhook={webhook} attempts={attempts.data} />
      ) : (
        <WebhookAttemptsEmpty webhook={webhook} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Webhook = () => {
  const params = useParams();
  const id = params.id!;

  const webhook: any = useQuery(["webhooks", id], () => api(`/v1/webhooks/${id}`));

  if (webhook.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (webhook.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {webhook.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <WebhookOverview webhook={webhook.data} />
      <WebhookSecret webhook={webhook.data} />
      <WebhookAttempts webhook={webhook.data} />
    </>
  );
};

export default Webhook;
