import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  colors,
  font,
  Form,
  FormTextInput,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import Button from "../components/Button";
import format from "../utils/format";
import { Card, CardContainer, CardContent } from "../components/Card";
import TableLinkCell from "../components/TableLinkCell";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const ApikeyOverview = ({ apikey }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const delete_ = useMutation<unknown, unknown, any>(
    () => api(`/v1/apikeys/${apikey.id}`, { body: {}, method: "DELETE" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["apikeys", apikey.id]);
        navigate("/apikeys");
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ name }) => api(`/v1/apikeys/${apikey.id}`, { body: { name }, method: "PATCH" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["apikeys", apikey.id], data);
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isDeleteOpen} onRequestClose={() => setIsDeleteOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Delete API key</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to delete <b>{apikey.id}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
                <Button color="red" disabled={delete_.isLoading} onClick={() => delete_.mutate({})}>
                  Delete
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ name: apikey.name }}
          onSubmit={(values: any) => update.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit API key</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="send" style={{ marginRight: spacing.small }} />
                {apikey.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {apikey.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {apikey.name}
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>Edit</Button>
              {/* @ts-ignore */}
              {/* <Button disabled style={{ color: colors.yellow }}>
                Disable
              </Button> */}
              {/* @ts-ignore */}
              <Button onClick={() => setIsDeleteOpen(true)} style={{ color: colors.red }}>
                Delete
              </Button>
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{}}>
            <Box padding={{ right: spacing.xlarge }}>
              <Text color="black400" size="small">
                Status
              </Text>
              <Box margin={{ top: spacing.small }}>Enabled</Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Date
              </Text>
              <Box margin={{ top: spacing.small }}>{format.timestamp(apikey.created_at)}</Box>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const ApikeySecret = ({ apikey }: any) => {
  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Secret
          </Text>
        </Box>
      </CardContent>
      <CardContent>The secret is only revealed once to keep your app secure.</CardContent>
    </CardContainer>
  );
};

const ApikeyLogsEmpty = ({ apikey }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any logs from <Text weight="bold">{apikey.id}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const ApikeyLogsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const ApikeyLogsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const ApikeyLogsTable = ({ logs }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Method</TableHeaderCell>
        <TableHeaderCell align="left">Endpoint</TableHeaderCell>
        <TableHeaderCell align="left">Status</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {logs.items.map((l: any) => (
        <TableRow key={l.id}>
          <TableLinkCell to={`/logs/${l.id}`}>{l.method}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/logs/${l.id}`}>
            {l.endpoint}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/logs/${l.id}`}>
            {l.status}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={l.created_at} to={`/logs/${l.id}`}>
            {format.timestamp(l.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ApikeyLogs = ({ apikey }: any) => {
  const logs = useQuery(["logs", apikey.id], () => api(`/v1/logs?apikey=${apikey.id}`));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Logs
          </Text>
        </Box>
      </CardContent>
      {logs.isLoading ? (
        <ApikeyLogsLoading />
      ) : logs.error ? (
        <ApikeyLogsError error={logs.error} />
      ) : logs.data.items.length ? (
        <ApikeyLogsTable logs={logs.data} />
      ) : (
        <ApikeyLogsEmpty apikey={apikey} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Apikey = () => {
  const params = useParams();
  const id = params.id!;

  const apikey: any = useQuery(["apikeys", id], () => api(`/v1/apikeys/${id}`));

  if (apikey.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (apikey.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {apikey.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <ApikeyOverview apikey={apikey.data} />
      <ApikeySecret apikey={apikey.data} />
      <ApikeyLogs apikey={apikey.data} />
    </>
  );
};

export default Apikey;
