import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  colors,
  Heading,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Text } from "../components/Text";
import api from "../utils/api";
import format from "../utils/format";

const Metrics = () => {
  const metrics: any = useQuery(["apps", "current", "metrics"], () =>
    api("/v1/apps/current/metrics")
  );

  return (
    <CardContainer>
      <CardContent>
        <Text size="medium" weight="medium">
          Metrics
        </Text>
      </CardContent>
      {metrics.isLoading ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Loading...</Box>
        </CardContent>
      ) : metrics.error ? (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>Error: {metrics.error.message}</Box>
        </CardContent>
      ) : Object.entries(metrics.data).length ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell align="left"></TableHeaderCell>
              <TableHeaderCell align="left">New</TableHeaderCell>
              <TableHeaderCell align="left">Active</TableHeaderCell>
              <TableHeaderCell align="left">Total</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(metrics.data).map(([key, value]: any) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value.new !== null ? format.number(value.new) : "—"}</TableCell>
                <TableCell>{value.active !== null ? format.number(value.active) : "—"}</TableCell>
                <TableCell>{value.total !== null ? format.number(value.total) : "—"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <CardContent>
          <Box flex={{ justifyContent: "center" }}>You don't have any metrics.</Box>
        </CardContent>
      )}
    </CardContainer>
  );
};

const Home = () => {
  const user: any = useQuery(["users", "current"], () => api("/v1/users/current"));
  return (
    <>
      <Card>
        <Heading level={4}>Hi {user.data.name.split(" ")[0]}, Welcome to Triangle!</Heading>
      </Card>
      <div style={{ display: "flex", gap: spacing.large, marginBottom: spacing.large }}>
        <Card containerProps={{ style: { flex: 1, marginTop: spacing.large } }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>Docs</b>
            <Anchor href="https://www.triangleplatform.com/docs" target="_blank">
              Go →
            </Anchor>
          </div>
          <div style={{ marginTop: spacing.medium }}>
            You'll find guides, resources, and references to build with Triangle.
          </div>
        </Card>
        <Card containerProps={{ style: { flex: 1 } }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>API Reference</b>
            <Anchor href="https://www.triangleplatform.com/docs/api" target="_blank">
              Go →
            </Anchor>
          </div>
          <div style={{ marginTop: spacing.medium }}>
            Explore server-side API objects, endpoints, and responses
          </div>
        </Card>
      </div>
      <Metrics />
    </>
  );
};

export default Home;
