import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  Heading,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const EventsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any events.</Text>
    </Box>
  </CardContent>
);

const EventsTable = ({ events }: { events: any }) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Event</TableHeaderCell>
        <TableHeaderCell align="left">Id</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {events.items.map((event: any) => (
        <TableRow key={event.id}>
          <TableLinkCell to={`/events/${event.id}`}>{event.type}</TableLinkCell>
          <TableLinkCell tabIndex="-1" to={`/events/${event.id}`}>
            {event.id}
          </TableLinkCell>
          <TableLinkCell tabIndex="-1" title={event.created_at} to={`/events/${event.id}`}>
            {format.timestamp(event.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const EventsList = ({ events }: { events: any }) => {
  if (events.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (events.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {events.error.message}</Box>
      </Card>
    );
  }

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Button disabled>Filter</Button>
        </Box>
      </CardContent>
      {events.data.items.length ? <EventsTable events={events.data} /> : <EventsEmpty />}
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            {events.data.items.length} result{events.data.items.length === 1 ? "" : "s"}
          </Box>
          <Box>
            <Button disabled>Previous</Button>
            <Button disabled>Next</Button>
          </Box>
        </Box>
      </CardContent>
    </CardContainer>
  );
};

const Events = () => {
  const events = useQuery("events", () => api("/v1/events"));
  return (
    <>
      <Card>
        <Heading level={4}>Events</Heading>
      </Card>
      <EventsList events={events} />
    </>
  );
};

export default Events;
