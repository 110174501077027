import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  colors,
  Form,
  FormTextInput,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import Link from "../components/Link";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const AccountOverview = ({ account }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const delete_ = useMutation<unknown, unknown, any>(
    () => api(`/v1/accounts/${account.id}`, { body: {}, method: "DELETE" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["accounts", account.id]);
        navigate("/accounts");
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ name }) =>
      api(`/v1/accounts/${account.id}?expand[]=network.chain`, {
        body: { name },
        method: "PATCH",
      }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["accounts", account.id], data);
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isDeleteOpen} onRequestClose={() => setIsDeleteOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Delete account</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to delete <b>{account.id}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
                <Button color="red" disabled={delete_.isLoading} onClick={() => delete_.mutate({})}>
                  Delete
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ name: account.name }}
          onSubmit={(values: any) => update.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit account</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="qrCode" style={{ marginRight: spacing.small }} />
                {account.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {account.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {account.name}
            </Text>
            <div>
              <Button onClick={() => setIsOpen(true)}>Edit</Button>
              {/* @ts-ignore */}
              <Button onClick={() => setIsDeleteOpen(true)} style={{ color: colors.red }}>
                Delete
              </Button>
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{}}>
            <Box padding={{ right: spacing.xlarge }}>
              <Text color="black400" size="small">
                Chain
              </Text>
              <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
                <img
                  src={`${process.env.REACT_APP_CDN_HOST}/img/${account.network.chain.id}.png`}
                  style={{ height: "16px", marginRight: "8px", width: "16px" }}
                />
                {account.network.chain.name}
              </Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Network
              </Text>
              <Box margin={{ top: spacing.small }}>{account.network.name}</Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Address
              </Text>
              <Box flex={{ alignItems: "center" }} margin={{ top: spacing.small }}>
                {account.address}
                <Anchor href={account.explorer_url} style={{ display: "flex" }} target="_blank">
                  <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
                </Anchor>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const AccountBalanceError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const AccountBalanceLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const AccountBalanceTable = ({ account, balance }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Amount</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>
          <Box flex={{ alignItems: "center" }}>
            <img
              src={balance.logo_url}
              style={{ height: "16px", marginRight: "8px", width: "16px" }}
            />
            {balance.name}
          </Box>
        </TableCell>
        <TableCell>
          {balance.balance} {balance.symbol}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const AccountBalance = ({ account }: any) => {
  const [isOpenReceive, setIsOpenReceive] = React.useState(false);

  // const balance = useQuery(["balance", account.id], () =>
  //   api(`/v1/accounts/${account.id}/balance`)
  // );
  const balance = useQuery(["balance", account.id], () => triangle.accounts.balance(account.id));

  return (
    <>
      <Modal isOpen={isOpenReceive} onRequestClose={() => setIsOpenReceive(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Receive</Text>
          </CardContent>
          <CardContent style={{ textAlign: "center" }}>
            <Box margin={{ bottom: spacing.large }}>
              <Text size="large" weight="bold">
                {account.network.chain.name} Wallet Address
              </Text>
            </Box>
            <QRCodeSVG fgColor={colors.black} size={256} value={account.address} />
            <Box margin={{ top: spacing.large }}>
              <Text>{account.address}</Text>
            </Box>
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsOpenReceive(false)}>Close</Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Balance
            </Text>
            <div>
              <Button onClick={() => setIsOpenReceive(true)}>Receive</Button>
            </div>
          </Box>
        </CardContent>
        {balance.isLoading ? (
          <AccountBalanceLoading />
        ) : balance.error ? (
          <AccountBalanceError error={balance.error} />
        ) : (
          <AccountBalanceTable account={account} balance={balance.data} />
        )}
      </CardContainer>
    </>
  );
};

const AccountNftsEmpty = ({ account }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any NFTs in <Text weight="bold">{account.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const AccountNftsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const AccountNftsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const AccountNftsTable = ({ account, nfts }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Token ID</TableHeaderCell>
        <TableHeaderCell align="left">Contract</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {nfts.items.map((nft: any) => (
        <TableRow key={`${nft.contract}-${nft.token_id}`}>
          <TableCell>
            <Box flex={{ alignItems: "center" }}>
              {nft.logo_url?.endsWith(".mp4") ? (
                <video style={{ height: "16px", marginRight: "8px", width: "16px" }}>
                  <source src={nft.logo_url} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={nft.logo_url}
                  style={{ height: "16px", marginRight: "8px", width: "16px" }}
                />
              )}
              {nft.name}
            </Box>
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {nft.token_id}
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {nft.contract}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const AccountNfts = ({ account }: any) => {
  // const nfts = useQuery(["nfts", account.id], () => api(`/v1/accounts/${account.id}/nfts`));
  const nfts = useQuery(["nfts", account.id], () => triangle.accounts.nfts(account.id));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            NFTs
          </Text>
        </Box>
      </CardContent>
      {nfts.isLoading ? (
        <AccountNftsLoading />
      ) : nfts.error ? (
        <AccountNftsError error={nfts.error} />
      ) : nfts.data.items.length ? (
        <AccountNftsTable account={account} nfts={nfts.data} />
      ) : (
        <AccountNftsEmpty account={account} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const AccountTokensEmpty = ({ account }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any tokens in <Text weight="bold">{account.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const AccountTokensError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const AccountTokensLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const AccountTokensTable = ({ account, tokens }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Name</TableHeaderCell>
        <TableHeaderCell align="left">Amount</TableHeaderCell>
        <TableHeaderCell align="left">Contract</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {tokens.items.map((token: any) => (
        <TableRow key={token.contract}>
          <TableCell>
            <Box flex={{ alignItems: "center" }}>
              <img
                src={token.logo_url}
                style={{ height: "16px", marginRight: "8px", width: "16px" }}
              />
              {token.name}
            </Box>
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {token.balance} {token.symbol}
          </TableCell>
          <TableCell style={{ maxWidth: "256px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {token.contract}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const AccountTokens = ({ account }: any) => {
  // const tokens = useQuery(["tokens", account.id], () => api(`/v1/accounts/${account.id}/tokens`));
  const tokens = useQuery(["tokens", account.id], () => triangle.accounts.tokens(account.id));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Tokens
          </Text>
        </Box>
      </CardContent>
      {tokens.isLoading ? (
        <AccountTokensLoading />
      ) : tokens.error ? (
        <AccountTokensError error={tokens.error} />
      ) : tokens.data.items.length ? (
        <AccountTokensTable account={account} tokens={tokens.data} />
      ) : (
        <AccountTokensEmpty account={account} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const AccountTxsEmpty = ({ account }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any txs in <Text weight="bold">{account.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const AccountTxsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const AccountTxsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const AccountTxsTable = ({ account, txs }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Hash</TableHeaderCell>
        <TableHeaderCell align="left">Block</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {txs.items.map((t: any) => (
        <TableRow key={t.id}>
          <TableCell style={{ maxWidth: "128px" }}>
            <Box flex={{ alignItems: "center" }}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{t.hash}</span>
              <Anchor
                href={t.explorer_url}
                style={{ alignItems: "center", display: "flex" }}
                target="_blank"
              >
                <Icon icon="open" style={{ marginLeft: spacing.small }} type="outline" />
              </Anchor>
            </Box>
          </TableCell>
          <TableCell>{t.block.number}</TableCell>
          <TableCell title={t.timestamp}>{format.timestamp(t.timestamp)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const AccountTxs = ({ account }: any) => {
  const txs = useQuery(["txs", account.id], () => api(`/v1/accounts/${account.id}/txs`));
  // const txs = useQuery(["txs", account.id], () => triangle.accounts.txs(account.id));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Txs
          </Text>
        </Box>
      </CardContent>
      {txs.isLoading ? (
        <AccountTxsLoading />
      ) : txs.error ? (
        <AccountTxsError error={txs.error} />
      ) : txs.data.items.length ? (
        <AccountTxsTable account={account} txs={txs.data} />
      ) : (
        <AccountTxsEmpty account={account} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Account = () => {
  const params = useParams();
  const id = params.id!;

  // const account: any = useQuery(["accounts", id], () =>
  //   api(`/v1/accounts/${id}?expand[]=network.chain`)
  // );
  const account: any = useQuery(["accounts", id], () =>
    triangle.accounts.retrieve(id, { expand: ["network.chain"] })
  );

  if (account.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (account.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {account.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <AccountOverview account={account.data} />
      <AccountBalance account={account.data} />
      <AccountTokens account={account.data} />
      <AccountNfts account={account.data} />
      <AccountTxs account={account.data} />
    </>
  );
};

export default Account;
