import React from "react";
import Triangle from "triangle";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  font,
  Form,
  FormSelect,
  FormRadioGroup,
  FormTextInput,
  Heading,
  Paragraph,
  spacing,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Modal } from "../components/Modal";
import Paginate from "../components/Paginate";
import Radio from "../components/Radio";
import TableLinkCell from "../components/TableLinkCell";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const VaultsEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="256px">
      <Text>You don't have any vaults.</Text>
    </Box>
  </CardContent>
);

const VaultsTable = ({ vaults }: { vaults: any }) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Vault</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {vaults.items.map((vault: any) => (
        <TableRow key={vault.id}>
          <TableLinkCell to={`/vaults/${vault.id}`}>{vault.name}</TableLinkCell>
          <TableLinkCell tabIndex="-1" title={vault.created_at} to={`/vaults/${vault.id}`}>
            {format.timestamp(vault.created_at)}
          </TableLinkCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const VaultsList = ({ vaults }: { vaults: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const vault = useMutation<unknown, unknown, any>(
    ({ name, type }) => api("/v1/vaults", { body: { name, type }, method: "POST" }),
    // ({ name }) => triangle.vaults.create({ name }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries("vaults");
        setIsOpen(false);
      },
    }
  );

  if (vaults.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (vaults.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {vaults.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form
          initialValues={{ type: "single" }}
          onSubmit={(values: any) => {
            vault.mutate(values);
          }}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Create a vault</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <div style={{ marginTop: spacing.large }}>
                <FormRadioGroup
                  id="type"
                  label="Type"
                  name="type"
                  options={[
                    { checked: true, label: "Single (no additional setup)", value: "single" },
                    { label: "PIN (user passphrase encrypted)", value: "pin" },
                    { label: "Account (smart contract wallet)", value: "account" },
                    { label: "Non-custodial (requires SDK setup)", value: "noncustodial" },
                    { label: "MPC (requires infrastructure setup)", value: "mpc" },
                  ]}
                />
              </div>
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={vault.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button disabled>Filter</Button>
            <Button onClick={() => setIsOpen(true)}>New</Button>
          </Box>
        </CardContent>
        {vaults.data.items.length ? <VaultsTable vaults={vaults.data} /> : <VaultsEmpty />}
        <Paginate id="id" list={vaults.data} />
      </CardContainer>
    </>
  );
};

const Vaults = () => {
  const [searchParams] = useSearchParams();
  const vaults = useQuery(["vaults", searchParams.toString()], () =>
    triangle.vaults.list({
      ending_before: searchParams.get("ending_before"),
      // @ts-ignore
      limit: searchParams.get("limit") || undefined,
      starting_after: searchParams.get("starting_after"),
    })
  );
  return (
    <>
      <Card>
        <Heading level={4}>Vaults</Heading>
        <Paragraph>
          A vault is used to generate keys to create a group of wallets. You can think of this just
          like how Ledger or any other consumer wallet generates a single key that is used to create
          many wallets.{" "}
          <Anchor href="https://www.triangleplatform.com/docs/api/vaults" target="_blank">
            Learn more →
          </Anchor>
        </Paragraph>
      </Card>
      <VaultsList vaults={vaults} />
    </>
  );
};

export default Vaults;
