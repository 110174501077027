import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Triangle from "triangle";
import {
  Box,
  colors,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const EventOverview = ({ event }: any) => {
  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ justifyContent: "space-between" }}>
          <Text size="small">
            <Box flex={{ alignItems: "center" }}>
              <Icon icon="mail" style={{ marginRight: spacing.small }} />
              {event.object.toUpperCase()}
            </Box>
          </Text>
          <Text family="mono" size="small">
            {event.id}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="large" weight="bold">
            {event.id}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{}}>
          <Box padding={{ right: spacing.xlarge }}>
            <Text color="black400" size="small">
              Type
            </Text>
            <Box margin={{ top: spacing.small }}>{event.type}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Date
            </Text>
            <Box margin={{ top: spacing.small }}>{format.timestamp(event.created_at)}</Box>
          </Box>
        </Box>
      </CardContent>
    </CardContainer>
  );
};

const EventData = ({ event }: any) => (
  <CardContainer>
    <CardContent>
      <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
        <Text size="medium" weight="medium">
          Data
        </Text>
      </Box>
    </CardContent>
    <CardContent>
      <pre style={{ margin: 0, overflow: "auto" }}>
        <code>{JSON.stringify(event.data, null, "  ")}</code>
      </pre>
    </CardContent>
  </CardContainer>
);

const EventAttemptsEmpty = ({ event }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any attempts in <Text weight="bold">{event.id}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const EventAttemptsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const EventAttemptsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const EventAttemptsTable = ({ event, attempts }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Webhook</TableHeaderCell>
        <TableHeaderCell align="left">Status</TableHeaderCell>
        <TableHeaderCell align="left" minimized>
          Date
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {attempts.items.map((a: any) => (
        <TableRow key={a.id}>
          <TableCell>{a.webhook.id}</TableCell>
          <TableCell>{a.error || a.response.status}</TableCell>
          <TableCell title={a.created_at}>{format.timestamp(a.created_at)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const EventAttempts = ({ event }: any) => {
  const attempts = useQuery(["attempts", event.id], () => api(`/v1/events/${event.id}/attempts`));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Webhook Attempts
          </Text>
        </Box>
      </CardContent>
      {attempts.isLoading ? (
        <EventAttemptsLoading />
      ) : attempts.error ? (
        <EventAttemptsError error={attempts.error} />
      ) : attempts.data.items.length ? (
        <EventAttemptsTable event={event} attempts={attempts.data} />
      ) : (
        <EventAttemptsEmpty event={event} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Event = () => {
  const params = useParams();
  const id = params.id!;

  const event: any = useQuery(["event", id], () => api(`/v1/events/${id}`));

  if (event.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (event.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {event.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <EventOverview event={event.data} />
      <EventData event={event.data} />
      <EventAttempts event={event.data} />
    </>
  );
};

export default Event;
