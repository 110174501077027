import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import api from "../utils/api";
import Anchor from "../components/Anchor";
import Button from "../components/Button";
import Link from "../components/Link";

const Login = () => {
  const navigate = useNavigate();

  const login = useMutation<unknown, unknown, any>(
    ({ email, password }) => api("/v1/login", { body: { email, password }, method: "POST" }),
    {
      onError: (error: any) => {
        if (error.session) {
          return navigate(`/mfa?email=${encodeURIComponent(error.email)}&session=${error.session}`);
        }
        window.alert(error.message);
      },
      onSuccess: () => {
        // const params = new URLSearchParams(this.props.location.search);
        navigate("/");
      },
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Log in
          </Heading>
          <Form
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values: any) => login.mutate(values)}
            // style={{width: "100%"}}
            // validateOnBlur={false}
            // validateOnChange={false}
            // validationSchema={yup.object().shape({
            //   email: yup.string().email().required(),
            //   password: yup.string().required(),
            // })}
          >
            <FormTextInput autoFocus label="Email" name="email" width="100%" />
            <FormTextInput label="Password" name="password" type="password" width="100%" />
            <Box padding={{ top: spacing.xlarge }}>
              {/* @ts-ignore */}
              <Button color="blue" disabled={login.isLoading} style={{ width: "100%" }} submit>
                Continue
              </Button>
              <Button
                color="blue"
                onClick={() => navigate(`/forgot`)}
                // @ts-ignore
                style={{ marginLeft: 0, marginTop: spacing.medium, width: "100%" }}
                type="secondary"
              >
                Forgot your password?
              </Button>
            </Box>
          </Form>
        </CardContent>
        <CardContent style={{ textAlign: "center" }}>
          Don&apos;t have an account?&nbsp;
          <Link to="/signup">Sign up</Link>
          {/* <Anchor href="https://www.triangleplatform.com/access">Get early access</Anchor> */}
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Login;
