import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Triangle from "triangle";
import { Box, colors, Icon, spacing } from "triangle-ui";

import api from "../utils/api";
import format from "../utils/format";
import { Card, CardContainer, CardContent } from "../components/Card";
import { Text } from "../components/Text";

const triangle = new Triangle("", { host: "" });

const LogOverview = ({ log }: any) => {
  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ justifyContent: "space-between" }}>
          <Text size="small">
            <Box flex={{ alignItems: "center" }}>
              <Icon icon="fileTrayFull" style={{ marginRight: spacing.small }} />
              {log.object.toUpperCase()}
            </Box>
          </Text>
          <Text family="mono" size="small">
            {log.id}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="large" weight="bold">
            {log.method} {log.endpoint}
          </Text>
        </Box>
      </CardContent>
      <CardContent>
        <Box flex={{}}>
          <Box padding={{ right: spacing.xlarge }}>
            <Text color="black400" size="small">
              Method
            </Text>
            <Box margin={{ top: spacing.small }}>{log.method}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Endpoint
            </Text>
            <Box margin={{ top: spacing.small }}>{log.endpoint}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Status
            </Text>
            <Box margin={{ top: spacing.small }}>{log.status}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              IP Address
            </Text>
            <Box margin={{ top: spacing.small }}>{log.ip_address}</Box>
          </Box>
          <Box
            padding={{ horizontal: spacing.xlarge }}
            style={{ borderLeft: `1px solid ${colors.gray100}` }}
          >
            <Text color="black400" size="small">
              Date
            </Text>
            <Box margin={{ top: spacing.small }}>{format.timestamp(log.created_at)}</Box>
          </Box>
        </Box>
      </CardContent>
    </CardContainer>
  );
};

const LogRequest = ({ log }: any) => (
  <CardContainer>
    <CardContent>
      <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
        <Text size="medium" weight="medium">
          Request
        </Text>
      </Box>
    </CardContent>
    <CardContent>
      <pre style={{ margin: 0, overflow: "auto" }}>
        <code>{JSON.stringify(log.request, null, "  ")}</code>
      </pre>
    </CardContent>
  </CardContainer>
);

const LogResponse = ({ log }: any) => (
  <CardContainer>
    <CardContent>
      <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
        <Text size="medium" weight="medium">
          Response
        </Text>
      </Box>
    </CardContent>
    <CardContent>
      <pre style={{ margin: 0, overflow: "auto" }}>
        <code>{JSON.stringify(log.response, null, "  ")}</code>
      </pre>
    </CardContent>
  </CardContainer>
);

const Log = () => {
  const params = useParams();
  const id = params.id!;

  const log: any = useQuery(["log", id], () => api(`/v1/logs/${id}`));

  if (log.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (log.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {log.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <LogOverview log={log.data} />
      <LogRequest log={log.data} />
      <LogResponse log={log.data} />
    </>
  );
};

export default Log;
