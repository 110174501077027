import React from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardContainer,
  CardContent,
  Form,
  FormTextInput,
  Heading,
  spacing,
} from "triangle-ui";

import Anchor from "../components/Anchor";
import api from "../utils/api";
import Link from "../components/Link";

const Signup = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const signup = useMutation<unknown, unknown, any>(
    ({ email, name, password }) =>
      api(`/v1/signup${searchParams.toString() ? `?${searchParams.toString()}` : ""}`, {
        body: { email, name, password },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: (data: any) => {
        navigate(`/verify?email=${encodeURIComponent(data.email)}`);
      },
    }
  );

  return (
    <Box style={{ margin: "auto", width: "512px" }}>
      <CardContainer style={{ marginTop: "128px" }}>
        <CardContent style={{ padding: "64px" }}>
          <div style={{ textAlign: "center" }}>
            <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
          </div>
          <Heading level={2} style={{ textAlign: "center" }}>
            Sign up
          </Heading>
          <Form
            initialValues={{
              email: "",
              name: "",
              password: "",
            }}
            onSubmit={(values: any) => signup.mutate(values)}
            // style={{width: "100%"}}
            // validateOnBlur={false}
            // validateOnChange={false}
            // validationSchema={yup.object().shape({
            //   email: yup.string().email().required(),
            //   name: yup.string().required(),
            //   password: yup.string().required(),
            // })}
          >
            <FormTextInput autoFocus label="Name" name="name" width="100%" />
            <FormTextInput label="Email" name="email" width="100%" />
            <FormTextInput label="Password" name="password" type="password" width="100%" />
            <Box margin={{ top: spacing.xlarge }} style={{ width: "100%" }}>
              <Button color="blue" disabled={signup.isLoading} style={{ width: "100%" }}>
                Continue
              </Button>
              <Box margin={{ top: spacing.medium }} style={{ textAlign: "center" }}>
                By signing up, you agree to Triangle's{" "}
                <Anchor href="https://www.triangleplatform.com/terms" target="_blank">
                  Terms of Use
                </Anchor>{" "}
                and consent to Triangle's{" "}
                <Anchor href="https://www.triangleplatform.com/privacy" target="_blank">
                  Privacy Policy
                </Anchor>
                .
              </Box>
            </Box>
          </Form>
        </CardContent>
        <CardContent style={{ textAlign: "center" }}>
          Have an account?&nbsp;
          <Link to="/login">Log in</Link>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default Signup;
