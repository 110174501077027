import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  colors,
  font,
  Form,
  FormSelect,
  FormTextInput,
  Icon,
  spacing,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "triangle-ui";

import api from "../utils/api";
import BarChart from "../components/BarChart";
import Button from "../components/Button";
import format from "../utils/format";
import { Card, CardContainer, CardContent } from "../components/Card";
import LineChart from "../components/LineChart";
import { Modal } from "../components/Modal";
import { Text } from "../components/Text";

export const COLOR = [
  { borderColor: colors.green, backgroundColor: colors.green100 },
  { borderColor: colors.blue, backgroundColor: colors.blue100 },
  { borderColor: colors.red, backgroundColor: colors.red100 },
  { borderColor: colors.yellow, backgroundColor: colors.yellow100 },
];

export enum chartwindow {
  HOUR = "1h",
  DAY = "1d",
  WEEK = "1w",
  MONTH = "1m",
  YEAR = "1y",
}

const RPC_URL_PLACEHOLDER =
  "https://rpc.triangleplatform.com/network/AbCdEfGhIjKlMnOpQrStUvWxYz0123456789aBcDeFg";

const RpcOverview = ({ rpc }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const delete_ = useMutation<unknown, unknown, any>(
    () => api(`/v1/rpcs/${rpc.id}`, { body: {}, method: "DELETE" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["rpcs", rpc.id]);
        navigate("/rpcs");
      },
    }
  );
  const update = useMutation<unknown, unknown, any>(
    ({ name }) => api(`/v1/rpcs/${rpc.id}`, { body: { name }, method: "PATCH" }),
    {
      onError: (error: any) => window.alert(error.message),
      onSuccess: (data) => {
        queryClient.setQueryData(["rpcs", rpc.id], data);
        setIsOpen(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isDeleteOpen} onRequestClose={() => setIsDeleteOpen(false)}>
        <CardContainer>
          <CardContent>
            <Text weight="bold">Delete RPC</Text>
          </CardContent>
          <CardContent>
            Are you sure you want to delete <b>{rpc.name}</b>?
          </CardContent>
          <CardContent>
            <Box flex={{ justifyContent: "space-between" }}>
              <div />
              <Box>
                <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
                <Button color="red" disabled={delete_.isLoading} onClick={() => delete_.mutate({})}>
                  Delete
                </Button>
              </Box>
            </Box>
          </CardContent>
        </CardContainer>
      </Modal>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <Form initialValues={{ name: rpc.name }} onSubmit={(values: any) => update.mutate(values)}>
          <CardContainer>
            <CardContent>
              <Text weight="bold">Edit RPC</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button color="blue" disabled={update.isLoading} submit>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ justifyContent: "space-between" }}>
            <Text size="small">
              <Box flex={{ alignItems: "center" }}>
                <Icon icon="send" style={{ marginRight: spacing.small }} />
                {rpc.object.toUpperCase()}
              </Box>
            </Text>
            <Text family="mono" size="small">
              {rpc.id}
            </Text>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="large" weight="bold">
              {rpc.name}
            </Text>
            <div>
              <Button disabled onClick={() => setIsOpen(true)}>
                Edit
              </Button>
              {/* @ts-ignore */}
              {/* <Button disabled style={{ color: colors.yellow }}>
                Disable
              </Button> */}
              {/* @ts-ignore */}
              <Button disabled onClick={() => setIsDeleteOpen(true)} style={{ color: colors.red }}>
                Delete
              </Button>
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <Box flex={{}}>
            <Box padding={{ right: spacing.xlarge }}>
              <Text color="black400" size="small">
                Status
              </Text>
              <Box margin={{ top: spacing.small }}>Enabled</Box>
            </Box>
            <Box
              padding={{ horizontal: spacing.xlarge }}
              style={{ borderLeft: `1px solid ${colors.gray100}` }}
            >
              <Text color="black400" size="small">
                Date
              </Text>
              <Box margin={{ top: spacing.small }}>{format.timestamp(rpc.created_at)}</Box>
            </Box>
          </Box>
        </CardContent>
      </CardContainer>
    </>
  );
};

const RpcUrl = ({ rpc }: any) => {
  const url: any = useMutation<unknown, unknown, any>(
    ({ id }) =>
      api(`/v1/rpcs/${id}/url`, {
        body: {},
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
    }
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            URL
          </Text>
          {url.data ? (
            <Button disabled={url.isLoading} onClick={() => url.reset()}>
              Hide
            </Button>
          ) : (
            <Button disabled={url.isLoading} onClick={() => url.mutate({ id: rpc.id })}>
              View
            </Button>
          )}
        </Box>
      </CardContent>
      <CardContent>
        {url.data ? (
          <div style={{ fontFamily: font.family.mono }}>{url.data.url}</div>
        ) : (
          <div
            style={{
              filter: "blur(4px)",
              fontFamily: font.family.mono,
              userSelect: "none",
            }}
          >
            {RPC_URL_PLACEHOLDER}
          </div>
        )}
      </CardContent>
    </CardContainer>
  );
};

const RpcLogsEmpty = ({ rpc }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any logs in <Text weight="bold">{rpc.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const RpcLogsError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const RpcLogsLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const RpcChartEmpty = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>You don't have any RPC data.</Text>
    </Box>
  </CardContent>
);

const RpcChartError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const RpcLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const RpcLogsTable = ({ rpc, logs }: any) => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHeaderCell align="left">Provider</TableHeaderCell>
        <TableHeaderCell align="left">Method</TableHeaderCell>
        <TableHeaderCell align="left">Latency</TableHeaderCell>
        <TableHeaderCell align="left">Date</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {logs.items.map((log: any) => (
        <TableRow key={log.time}>
          <TableCell>{log.provider}</TableCell>
          <TableCell>{log.method}</TableCell>
          <TableCell>{log.measure_value}ms</TableCell>
          <TableCell>{format.timestamp(log.time)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const RpcProvidersEmpty = ({ rpc }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>
        You don't have any providers in <Text weight="bold">{rpc.name}</Text>.
      </Text>
    </Box>
  </CardContent>
);

const RpcProvidersError = ({ error }: any) => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Error: {error.message}</Text>
    </Box>
  </CardContent>
);

const RpcProvidersLoading = () => (
  <CardContent>
    <Box flex={{ alignItems: "center", justifyContent: "center" }} height="128px">
      <Text>Loading...</Text>
    </Box>
  </CardContent>
);

const RpcProvidersTable = ({ rpc, providers }: any) => {
  const [isEditOpen, setIsEditOpen] = React.useState("");
  const queryClient = useQueryClient();

  const editRpc = useMutation<unknown, unknown, any>(
    ({ id, name, endpoint, include, exclude, enabled }) =>
      api(`/v1/rpcs/${rpc.id}/providers/${id}`, {
        body: { name, endpoint, include, exclude, enabled: enabled?.value },
        method: "PATCH",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["rpcs", rpc.id, "providers"]);
        setIsEditOpen("");
      },
    }
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell align="left">Name</TableHeaderCell>
          <TableHeaderCell align="left">Endpoint</TableHeaderCell>
          <TableHeaderCell align="left">Enabled</TableHeaderCell>
          <TableHeaderCell align="left" minimized>
            Date
          </TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {providers.items.map((provider: any) => (
          <React.Fragment key={`${provider.id}`}>
            <Modal
              isOpen={isEditOpen === `${provider.id}`}
              onRequestClose={() => setIsEditOpen("")}
            >
              <Form
                initialValues={{
                  name: provider.name,
                  id: provider.id,
                  endpoint: provider.endpoint,
                  include: provider.include,
                  exclude: provider.exclude,
                  enabled: { label: `${provider.enabled}`, value: provider.enabled },
                }}
                onSubmit={(values: any) => editRpc.mutate(values)}
              >
                <CardContainer>
                  <CardContent>
                    <Text weight="bold">Edit RPC Endpoint</Text>
                  </CardContent>
                  <CardContent>
                    <FormTextInput autoFocus disabled label="name" name="name" width="100%" />
                    <FormTextInput disabled label="endpoint" name="endpoint" width="100%" />
                    <FormTextInput
                      label="include"
                      name="include"
                      placeholder="eth_getBlockByNumber,eth_getTransactionByHash"
                      width="100%"
                    />
                    <FormTextInput
                      label="exclude"
                      name="exclude"
                      placeholder="trace_transaction"
                      width="100%"
                    />
                    <FormSelect
                      label="enabled"
                      name="enabled"
                      // @ts-ignore
                      menuPortalTarget={document.body}
                      options={[
                        { label: `true`, value: true },
                        { label: `false`, value: false },
                      ]}
                    />
                  </CardContent>
                  <CardContent>
                    <Box flex={{ justifyContent: "space-between" }}>
                      <div />
                      <Box>
                        <Button onClick={() => setIsEditOpen("")}>Cancel</Button>
                        <Button color="blue" disabled={editRpc.isLoading} submit>
                          Edit
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </CardContainer>
              </Form>
            </Modal>
            <TableRow key={provider.id}>
              <TableCell>{provider.name}</TableCell>
              <TableCell>{provider.endpoint}</TableCell>
              <TableCell>{`${provider.enabled}`}</TableCell>
              <TableCell title={provider.created_at}>
                {format.timestamp(provider.created_at)}
              </TableCell>
              <TableCell align="right">
                <Button onClick={() => setIsEditOpen(`${provider.id}`)}>Edit</Button>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const RpcProviders = ({ rpc }: any) => {
  const [isOpenNew, setIsOpenNew] = React.useState(false);

  const providers = useQuery(["rpcs", rpc.id, "providers"], () =>
    api(`/v1/rpcs/${rpc.id}/providers`)
  );

  const queryClient = useQueryClient();
  const provider = useMutation<unknown, unknown, any>(
    ({ name, endpoint, include, exclude }) =>
      api(`/v1/rpcs/${rpc.id}/providers`, {
        body: { name, endpoint, include, exclude },
        method: "POST",
      }),
    {
      onError: (err: any) => window.alert(err.message),
      onSuccess: () => {
        queryClient.invalidateQueries(["rpcs", rpc.id, "providers"]);
        setIsOpenNew(false);
      },
    }
  );

  return (
    <>
      <Modal isOpen={isOpenNew} onRequestClose={() => setIsOpenNew(false)}>
        <Form
          initialValues={{ address: rpc.address }}
          onSubmit={(values: any) => provider.mutate(values)}
        >
          <CardContainer>
            <CardContent>
              <Text weight="bold">Add a provider</Text>
            </CardContent>
            <CardContent>
              <FormTextInput autoFocus label="Name" name="name" width="100%" />
              <FormTextInput label="Endpoint" name="endpoint" placeholder="https://" width="100%" />
              <FormTextInput
                label="Include"
                name="include"
                placeholder="eth_getBlockByNumber,eth_getTransactionByHash"
                width="100%"
              />
              <FormTextInput
                label="Exclude"
                name="exclude"
                placeholder="trace_transaction"
                width="100%"
              />
            </CardContent>
            <CardContent>
              <Box flex={{ justifyContent: "space-between" }}>
                <div />
                <Box>
                  <Button onClick={() => setIsOpenNew(false)}>Cancel</Button>
                  <Button color="blue" disabled={provider.isLoading} submit>
                    Create
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardContainer>
        </Form>
      </Modal>
      <CardContainer>
        <CardContent>
          <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
            <Text size="medium" weight="medium">
              Providers
            </Text>
            <Button onClick={() => setIsOpenNew(true)}>New</Button>
          </Box>
        </CardContent>
        {providers.isLoading ? (
          <RpcProvidersLoading />
        ) : providers.error ? (
          <RpcProvidersError error={providers.error} />
        ) : providers.data.items.length ? (
          <RpcProvidersTable rpc={rpc} providers={providers.data} />
        ) : (
          <RpcProvidersEmpty rpc={rpc} />
        )}
        <CardContent>...</CardContent>
      </CardContainer>
    </>
  );
};

const RpcLogs = ({ rpc }: any) => {
  const logs = useQuery(["rpcs", rpc.id, "logs"], () => api(`/v1/rpcs/${rpc.id}/logs`));

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            Logs
          </Text>
        </Box>
      </CardContent>
      {logs.isLoading ? (
        <RpcLogsLoading />
      ) : logs.error ? (
        <RpcLogsError error={logs.error} />
      ) : logs.data.items.length ? (
        <RpcLogsTable rpc={rpc} logs={logs.data} />
      ) : (
        <RpcLogsEmpty rpc={rpc} />
      )}
      <CardContent>...</CardContent>
    </CardContainer>
  );
};

const Line = ({ name, rpc }: any) => {
  const [timeframe, setTimeframe] = React.useState(chartwindow.DAY);
  const data = useQuery(["rpcs", rpc.id, "metrics", "latency", timeframe], () =>
    api(`/v1/rpcs/${rpc.id}/metrics/latency?function=avg&timeframe=${timeframe}`)
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            {name}
          </Text>
          <div>
            <Button
              type="primary"
              color={timeframe === chartwindow.HOUR ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.HOUR)}
            >
              Hour
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.DAY ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.DAY)}
            >
              Day
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.WEEK ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.WEEK)}
            >
              Week
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.MONTH ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.MONTH)}
            >
              Month
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.YEAR ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.YEAR)}
            >
              Year
            </Button>
          </div>
        </Box>
      </CardContent>
      <CardContent>
        {data.isLoading ? (
          <RpcLoading />
        ) : data.error ? (
          <RpcChartError error={data.error} />
        ) : data.data.length ? (
          <LineChart data={data.data} timewindow={timeframe} />
        ) : (
          <RpcChartEmpty />
        )}
      </CardContent>
    </CardContainer>
  );
};

const Bar = ({ name, rpc }: any) => {
  const [timeframe, setTimeframe] = React.useState(chartwindow.DAY);
  const data = useQuery(["rpcs", rpc.id, "metrics", "method", timeframe], () =>
    api(`/v1/rpcs/${rpc.id}/metrics/method?timeframe=${timeframe}`)
  );

  return (
    <CardContainer>
      <CardContent>
        <Box flex={{ justifyContent: "space-between" }}>
          <Text size="medium" weight="medium">
            {name}
          </Text>
          <div>
            <Button
              type="primary"
              color={timeframe === chartwindow.HOUR ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.HOUR)}
            >
              Hour
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.DAY ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.DAY)}
            >
              Day
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.WEEK ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.WEEK)}
            >
              Week
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.MONTH ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.MONTH)}
            >
              Month
            </Button>
            <Button
              type="primary"
              color={timeframe === chartwindow.YEAR ? "blue" : undefined}
              onClick={() => setTimeframe(chartwindow.YEAR)}
            >
              Year
            </Button>
          </div>
        </Box>
      </CardContent>
      <CardContent>
        {data.isLoading ? (
          <RpcLoading />
        ) : data.error ? (
          <RpcChartError error={data.error} />
        ) : data.data.datasets.length ? (
          <BarChart data={data.data} />
        ) : (
          <RpcChartEmpty />
        )}
      </CardContent>
    </CardContainer>
  );
};

const Rpc = () => {
  const params = useParams();
  const id = params.id!;

  const rpc: any = useQuery(["rpcs", id], () => api(`/v1/rpcs/${id}`));

  if (rpc.isLoading) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Loading...</Box>
      </Card>
    );
  } else if (rpc.error) {
    return (
      <Card>
        <Box flex={{ justifyContent: "center" }}>Error: {rpc.error.message}</Box>
      </Card>
    );
  }

  return (
    <>
      <RpcOverview rpc={rpc.data} />
      <RpcUrl rpc={rpc.data} />
      <RpcProviders rpc={rpc.data} />
      <Line name="Latency" rpc={rpc.data} />
      <Bar name="Methods" rpc={rpc.data} />
      <RpcLogs rpc={rpc.data} />
    </>
  );
};

export default Rpc;
